import React from 'react';
import { Logo } from './../logo';
import { Navigation } from './../nav';
import { BtnNav } from './../btnNav';

const View = React.memo(() => {
	return (
		<header>
			<div className="wrapper">
				<Logo/>	
				<Navigation/>
				<BtnNav/>
			</div>
		</header>);
});

export { View };