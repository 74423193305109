import React from 'react';
import { useSelector } from 'react-redux';

const View = React.memo(() => {
    const color = useSelector(state => state.colorSite);
    if (color === 'white') {
	    return (
            <svg id='eg_3' className="white_eg_3" width="188" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.5 130.082V94H140.5V130.082C136.615 139.758 120.23 157 90.8757 157C62.6324 157 45.7504 140.783 39.5 130.082Z" fill="url(#p0_linear)"/>
                <path d="M39.5 122.082V86H140.5V122.082C136.615 131.758 120.23 149 90.8757 149C62.6324 149 45.7504 132.783 39.5 122.082Z" fill="white"/>
                <path d="M92.4537 27.0593L18.8989 64.5976C16.3422 65.9024 15.9514 69.3958 18.1565 71.2333L81.8752 124.332C83.1097 125.361 84.8399 125.551 86.2683 124.815L168.925 82.2191C171.643 80.8181 171.848 77.0087 169.295 75.3246L96.4747 27.2833C95.2724 26.4902 93.7366 26.4046 92.4537 27.0593Z" fill="url(#p1_linear)"/>
                <path stroke="url(#p2_linear)" strokeWidth="3" strokeLinecap="round" strokeDasharray="5.2 5.2" d="M154.206 88.0296C136 83 93.5 68 93.5 68"></path>
                <g id='rope_eg_3'>
                    <path d="M154.206 140.5L153.206 88.0296C136 83 93.5 68 93.5 93.5" stroke="url(#p2_linear)" strokeWidth="3" strokeLinecap="round" strokeDasharray="5.2 5.2"/>
                    <path d="M161.596 161.374C159.168 162.749 156.356 163.282 153.596 162.891C150.835 162.5 148.281 161.206 146.328 159.21C144.376 157.215 143.134 154.628 142.797 151.853C142.459 149.078 143.044 146.268 144.461 143.86C145.878 141.452 148.047 139.58 150.633 138.534C153.219 137.489 156.076 137.328 158.762 138.078C161.448 138.827 163.812 140.444 165.488 142.678C167.164 144.912 168.058 147.639 168.032 150.435L155.368 150.314L161.596 161.374Z" fill="#101527"/>
                </g>
                <path opacity="0.3" d="M83.5 118L30 74.5L83.5 110C100.5 104.667 134.8 93.9 136 93.5C137.2 93.1 101.5 109.667 83.5 118Z" fill="white"/>
                <defs>
                    <linearGradient id="p0_linear" x1="90" y1="94" x2="90" y2="157" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E2EDF3"/>
                        <stop offset="1" stopColor="#CFD9DE"/>
                    </linearGradient>
                    <linearGradient id="p1_linear" x1="94.25" y1="26" x2="94.25" y2="126.029" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E2EDF3"/>
                        <stop offset="1" stopColor="#CFD9DE"/>
                    </linearGradient>
                    <linearGradient id="p2_linear" x1="123.853" y1="68" x2="123.853" y2="140.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FAFF11"/>
                        <stop offset="1" stopColor="#02E319"/>
                    </linearGradient>
                </defs>
            </svg>
        );
    }
    if (color === 'green') {
        return (
<svg id='eg_3' class="green_eg_3" width="188" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.5 130.082V94H140.5V130.082C136.615 139.758 120.23 157 90.8757 157C62.6324 157 45.7504 140.783 39.5 130.082Z" fill="#14E619"/>
                            <path d="M39.5 122.082V86H140.5V122.082C136.615 131.758 120.23 149 90.8757 149C62.6324 149 45.7504 132.783 39.5 122.082Z" fill="white"/>
                            <path d="M92.4537 27.0593L18.8989 64.5976C16.3422 65.9024 15.9514 69.3958 18.1565 71.2333L81.8752 124.332C83.1097 125.361 84.8399 125.551 86.2683 124.815L168.925 82.2191C171.643 80.8181 171.848 77.0087 169.295 75.3246L96.4747 27.2833C95.2724 26.4902 93.7366 26.4046 92.4537 27.0593Z" fill="url(#paint0_ggglinear)"/>
                            <path opacity="0.3" d="M83.5 118L30 74.5L83.5 110C100.5 104.667 134.8 93.9 136 93.5C137.2 93.1 101.5 109.667 83.5 118Z" fill="white"/>
                            <path stroke="#ffffff" stroke-width="3" stroke-linecap="round" stroke-dasharray="5.2 5.2" d="M154.206 88.0296C136 83 93.5 68 93.5 68"></path>
                            <g id='rope_eg_3'>
                                <path d="M154.206 140.5L153.206 88.0296C136 83 93.5 68 93.5 93.5" stroke="#ffffff" stroke-width="3" stroke-linecap="round" stroke-dasharray="5.2 5.2"/>
                                <path d="M161.596 161.374C159.169 162.749 156.356 163.282 153.596 162.891C150.836 162.5 148.281 161.206 146.329 159.21C144.376 157.215 143.135 154.628 142.797 151.853C142.46 149.078 143.045 146.268 144.462 143.86C145.878 141.452 148.048 139.58 150.634 138.534C153.219 137.489 156.077 137.328 158.763 138.078C161.449 138.827 163.813 140.444 165.489 142.678C167.165 144.912 168.059 147.639 168.032 150.435L155.369 150.314L161.596 161.374Z" fill="#101527"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_ggglinear" x1="28" y1="32.5" x2="137.5" y2="106.5" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FAFF11"/>
                                    <stop stop-color="#FAFF11"/>
                                    <stop offset="1" stop-color="#02E319"/>
                                </linearGradient>
                            </defs>
                        </svg>
        );
    }
    if (color === 'blue') {
        return (
<svg id='eg_3' class="blue_eg_3" width="188" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.5 130.082V94H140.5V130.082C136.615 139.758 120.23 157 90.8757 157C62.6324 157 45.7504 140.783 39.5 130.082Z" fill="url(#paint0_bbblinear)"/>
                            <path d="M39.5 122.082V86H140.5V122.082C136.615 131.758 120.23 149 90.8757 149C62.6324 149 45.7504 132.783 39.5 122.082Z" fill="#FFD600"/>
                            <path d="M92.4537 27.0593L18.8989 64.5976C16.3422 65.9024 15.9514 69.3958 18.1565 71.2333L81.8752 124.332C83.1097 125.361 84.8399 125.551 86.2683 124.815L168.925 82.2191C171.643 80.8181 171.848 77.0087 169.295 75.3246L96.4747 27.2833C95.2724 26.4902 93.7366 26.4046 92.4537 27.0593Z" fill="url(#paint1_bbblinear)"/>
                            <path opacity="0.3" d="M83.5 118L30 74.5L83.5 110C100.5 104.667 134.8 93.9 136 93.5C137.2 93.1 101.5 109.667 83.5 118Z" fill="white"/>
                            <path stroke="#ffffff" stroke-width="3" stroke-linecap="round" stroke-dasharray="5.2 5.2" d="M154.206 88.0296C136 83 93.5 68 93.5 68"></path>
                            <g id='rope_eg_3'>
                                <path d="M154.206 140.5L153.206 88.0296C136 83 93.5 68 93.5 93.5" stroke="#ffffff" stroke-width="3" stroke-linecap="round" stroke-dasharray="5.2 5.2"/>
                                <path d="M161.595 161.374C159.168 162.749 156.355 163.282 153.595 162.891C150.835 162.5 148.28 161.206 146.328 159.21C144.375 157.215 143.134 154.628 142.796 151.853C142.459 149.078 143.044 146.268 144.461 143.86C145.877 141.452 148.047 139.58 150.633 138.534C153.218 137.489 156.076 137.328 158.762 138.078C161.448 138.827 163.812 140.444 165.488 142.678C167.164 144.912 168.058 147.639 168.031 150.435L155.368 150.314L161.595 161.374Z" fill="#101527"/>
                            </g>
                            <defs>
                            <linearGradient id="paint0_bbblinear" x1="61.9955" y1="106.486" x2="91.1711" y2="167.697" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#009DFA"/>
                                <stop offset="1" stop-color="#007DC7"/>
                            </linearGradient>
                            <linearGradient id="paint1_bbblinear" x1="49.4705" y1="45.8256" x2="95.5885" y2="143.267" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#009DFA"/>
                                <stop offset="1" stop-color="#007DC7"/>
                            </linearGradient>
                            </defs>
                        </svg>
        );
    }
});

export { View };