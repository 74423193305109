import React from 'react';
import { useSelector } from 'react-redux';

const View = React.memo(() => {
    const color = useSelector(state => state.colorSite);
    if (color === 'white') {
	    return (
            <svg className="white_exp_elem_2" width="296" height="296" viewBox="0 0 296 296" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter007_i)">
                    <circle cx="148" cy="148" r="148" fill="white"/>
                </g>
                <mask id="mask007" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="296" height="296">
                    <circle cx="148" cy="148" r="148" fill="white"/>
                </mask>
                <g mask="url(#mask007)">
                    <g id="ptashka_win">
                        <path id="ptashka_win_wing_01" d="M219.165 132.459C220.585 134.46 214.096 136.918 208.905 136.769C209.982 137.566 213.847 137.61 213.637 138.845C213.427 140.081 210.435 140.666 208.199 140.237C206.047 139.806 204.673 139.228 202.809 138.13C204.696 139.43 207.755 141.363 206.698 142.125C205.64 142.888 202.046 142.15 197.261 137.967C197.732 138.904 199.055 140.497 198.162 141.426C197.27 142.355 194.53 139.349 193.878 137.229C192.427 132.299 196.83 127.615 201.853 128.848C204.435 129.485 206.96 130.361 209.507 131.109C214.128 132.456 218.452 131.084 219.165 132.459Z" fill="url(#paint007_linear)"/>
                        <path id="ptashka_win_wing_02" d="M77.2955 132.659C75.9225 134.692 82.4668 137.001 87.6526 136.732C86.5945 137.554 82.7315 137.686 82.97 138.916C83.2084 140.146 86.2128 140.663 88.4382 140.183C90.58 139.702 91.9396 139.092 93.7788 137.952C91.9213 139.295 88.9078 141.298 89.9827 142.036C91.0576 142.774 94.634 141.953 99.3213 137.661C98.8714 138.608 97.5857 140.232 98.4997 141.14C99.4136 142.048 102.083 138.979 102.686 136.845C104.023 131.883 99.5129 127.301 94.5204 128.65C91.9533 129.346 89.4492 130.281 86.9201 131.086C82.3318 132.54 77.9769 131.268 77.2955 132.659Z" fill="url(#paint107_linear)"/>
                        <g filter="url(#filter107_i)">
                            <ellipse cx="147.894" cy="147.625" rx="48.7373" ry="49.1804" fill="url(#paint207_linear)"/>
                        </g>
                        <path d="M165.827 155.438C164.015 159.463 160.924 162.773 157.035 164.855C153.146 166.937 148.675 167.675 144.317 166.954C139.959 166.232 135.956 164.092 132.93 160.865C129.904 157.639 128.023 153.505 127.579 149.107C127.136 144.708 128.155 140.29 130.477 136.538C132.8 132.785 136.297 129.908 140.426 128.353C144.554 126.797 149.084 126.65 153.313 127.935C157.541 129.219 161.232 131.863 163.813 135.457L147.537 147.146L165.827 155.438Z" fill="#101527"/>
                    </g>
                    <path d="M43 153.006C23 153.406 6.33333 167.84 0.5 175.006C-0.666667 180.84 -3 192.906 -3 194.506C-3 196.506 16 149.506 43 156.006C70 162.506 81 193.506 116 185.006C127.877 182.122 137.164 181.195 145.5 181.425L143.5 179L144.5 176L143 174.5L147.5 170.961C137.646 171.287 127.125 172.573 116 175.006C84 182.006 68 152.506 43 153.006Z" fill="url(#paint307_linear)"/>
                    <path d="M247.5 183.13C224 191.13 217 185.63 196.5 178.13C183.733 173.459 169.946 170.706 152.5 171L148.5 174.5L149.5 175.5L148 178.5L150 181.5C165.291 182.267 176.235 186.963 196.5 190.13C228.5 195.13 218 188.63 247.5 185.13C271.1 182.33 291.667 198.296 299 206.63V188.63C289.667 184.13 266.3 176.729 247.5 183.13Z" fill="url(#paint407_linear)"/>
                </g>
                <defs>
                <filter id="filter007_i" x="0" y="0" width="296" height="304" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="8"/>
                <feGaussianBlur stdDeviation="17.5"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.389035 0 0 0 0 0.499495 0 0 0 0 0.629167 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                </filter>
                <filter id="filter107_i" x="99.1566" y="98.4448" width="101.503" height="103.732" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dx="4.0284" dy="5.3712"/>
                <feGaussianBlur stdDeviation="2.6856"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.876 0 0 0 0 0.89717 0 0 0 0 0.9125 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                </filter>
                <linearGradient id="paint007_linear" x1="215.421" y1="127.166" x2="196.084" y2="140.883" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2EDF3"/>
                <stop offset="1" stopColor="#CFD9DE"/>
                </linearGradient>
                <linearGradient id="paint107_linear" x1="80.917" y1="127.282" x2="100.565" y2="140.549" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E2EDF3"/>
                <stop offset="1" stopColor="#CFD9DE"/>
                </linearGradient>
                <linearGradient id="paint207_linear" x1="130.074" y1="134.374" x2="157.148" y2="164.006" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="1" stopColor="#E0EAED"/>
                </linearGradient>
                <linearGradient id="paint307_linear" x1="-11.9821" y1="161.77" x2="71.2616" y2="246.649" gradientUnits="userSpaceOnUse">
                <stop offset="0.0447088" stopColor="#FDFC47"/>
                <stop offset="1" stopColor="#3EEC55"/>
                </linearGradient>
                <linearGradient id="paint407_linear" x1="316" y1="207" x2="247.47" y2="113.355" gradientUnits="userSpaceOnUse">
                <stop offset="0.0447088" stopColor="#FDFC47"/>
                <stop offset="1" stopColor="#3EEC55"/>
                </linearGradient>
                </defs>
            </svg>
        );
    }
    if (color === 'green') {
        return (
            <svg class="green_exp_elem_2" width="296" height="296" viewBox="0 0 296 296" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_iggg)">
                                <circle cx="148" cy="148" r="148" fill="white"/>
                            </g>
                            <mask id="mask04" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="296" height="296">
                                <circle cx="148" cy="148" r="148" fill="white"/>
                            </mask>
                            <g mask="url(#mask04)">
                                
                                <g id="ptashka_win">
                                    <path id="ptashka_win_wing_01" d="M205.697 115.494C208.15 115.494 206.401 122.21 203.277 126.357C204.55 125.94 206.821 122.813 207.707 123.699C208.593 124.585 207.34 127.364 205.697 128.939C204.1 130.445 202.834 131.231 200.859 132.117C203.012 131.329 206.358 129.953 206.368 131.256C206.378 132.56 203.697 135.065 197.517 136.548C198.554 136.705 200.618 136.548 200.859 137.813C201.101 139.079 197.064 139.574 194.957 138.879C190.097 137.211 188.824 130.909 192.735 127.526C194.749 125.788 196.925 124.236 199.008 122.591C202.781 119.602 204.163 115.28 205.697 115.494Z" fill="#101527"/>
                                    <path id="ptashka_win_wing_02" d="M89.9398 115.494C87.4863 115.494 89.2354 122.21 92.3601 126.357C91.087 125.94 88.8155 122.813 87.9294 123.699C87.0433 124.585 88.2964 127.364 89.9398 128.939C91.5368 130.445 92.8031 131.231 94.7772 132.117C92.6247 131.329 89.2785 129.953 89.2685 131.256C89.2586 132.56 91.94 135.065 98.1199 136.548C97.0831 136.705 95.0185 136.548 94.7772 137.813C94.536 139.079 98.5731 139.574 100.679 138.879C105.54 137.211 106.813 130.909 102.901 127.526C100.888 125.788 98.712 124.236 96.6289 122.591C92.8561 119.602 91.4739 115.28 89.9398 115.494Z" fill="#101527"/>
                                    <g filter="url(#filter1_iggg)">
                                        <ellipse cx="147.894" cy="147.625" rx="48.7373" ry="49.1804" fill="url(#paint0_linearggg)"/>
                                    </g>
                                    <path d="M165.827 155.438C164.014 159.463 160.924 162.773 157.035 164.855C153.146 166.937 148.675 167.675 144.317 166.954C139.959 166.232 135.956 164.092 132.93 160.865C129.903 157.639 128.023 153.505 127.579 149.107C127.136 144.708 128.154 140.29 130.477 136.538C132.8 132.785 136.297 129.908 140.425 128.353C144.554 126.797 149.084 126.65 153.313 127.935C157.541 129.219 161.232 131.863 163.813 135.457L147.536 147.146L165.827 155.438Z" fill="#101527"/>
                                </g>
                                
                                <path d="M43 153.006C23 153.406 6.33333 167.84 0.5 175.006C-0.666667 180.84 -3 192.906 -3 194.506C-3 196.506 16 149.506 43 156.006C70 162.506 81 193.506 116 185.006C127.877 182.122 137.164 181.195 145.5 181.425L143.5 179L144.5 176L143 174.5L147.5 170.961C137.646 171.287 127.125 172.573 116 175.006C84 182.006 68 152.506 43 153.006Z" fill="#101527"/>
                                <path d="M247.5 183.13C224 191.13 217 185.63 196.5 178.13C183.733 173.459 169.946 170.706 152.5 171L148.5 174.5L149.5 175.5L148 178.5L150 181.5C165.291 182.267 176.235 186.963 196.5 190.13C228.5 195.13 218 188.63 247.5 185.13C271.1 182.33 291.667 198.296 299 206.63V188.63C289.667 184.13 266.3 176.729 247.5 183.13Z" fill="#101527"/>
                            </g>
                            <defs>
                                <filter id="filter0_iggg" x="0" y="0" width="296" height="304" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="8"/>
                                    <feGaussianBlur stdDeviation="17.5"/>
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.389035 0 0 0 0 0.499495 0 0 0 0 0.629167 0 0 0 0.15 0"/>
                                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                                </filter>
                                <filter id="filter1_iggg" x="99.1562" y="98.4448" width="101.503" height="103.732" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="4.0284" dy="5.3712"/>
                                    <feGaussianBlur stdDeviation="2.6856"/>
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.876 0 0 0 0 0.89717 0 0 0 0 0.9125 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                                </filter>
                                <linearGradient id="paint0_linearggg" x1="93.3388" y1="119.196" x2="195.36" y2="147.669" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.0447088" stop-color="#FDFC47"/>
                                    <stop offset="1" stop-color="#3EEC55"/>
                                </linearGradient>
                            </defs>
            </svg>
        );
    }
    if (color === 'blue') {
        return (
            <svg class="blue_exp_elem_2" width="296" height="296" viewBox="0 0 296 296" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_ibebebe)">
                                <circle cx="148" cy="148" r="148" fill="white"/>
                            </g>
                            <mask id="mask016" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="296" height="296">
                                <circle cx="148" cy="148" r="148" fill="white"/>
                            </mask>
                            <g mask="url(#mask016)">
                                <g id="ptashka_win">
                                    <path id="ptashka_win_wing_01" d="M205.697 115.494C208.15 115.494 206.401 122.21 203.277 126.357C204.55 125.94 206.821 122.813 207.707 123.699C208.593 124.585 207.34 127.364 205.697 128.939C204.1 130.445 202.834 131.231 200.859 132.117C203.012 131.329 206.358 129.953 206.368 131.256C206.378 132.56 203.697 135.065 197.517 136.548C198.554 136.705 200.618 136.548 200.859 137.813C201.101 139.079 197.064 139.574 194.957 138.879C190.097 137.211 188.824 130.909 192.735 127.526C194.749 125.788 196.925 124.236 199.008 122.591C202.781 119.602 204.163 115.28 205.697 115.494Z" fill="url(#paint0_linearbebebe)"/>
                                    <path id="ptashka_win_wing_02" d="M89.9398 115.494C87.4863 115.494 89.2354 122.21 92.3601 126.357C91.087 125.94 88.8155 122.813 87.9294 123.699C87.0433 124.585 88.2964 127.364 89.9398 128.939C91.5368 130.445 92.8031 131.231 94.7772 132.117C92.6247 131.329 89.2785 129.953 89.2685 131.256C89.2586 132.56 91.94 135.065 98.1199 136.548C97.0831 136.705 95.0185 136.548 94.7772 137.813C94.536 139.079 98.5731 139.574 100.679 138.879C105.54 137.211 106.813 130.909 102.901 127.526C100.888 125.788 98.712 124.236 96.6289 122.591C92.8561 119.602 91.4739 115.28 89.9398 115.494Z" fill="url(#paint1_linearbebebe)"/>
                                    <g filter="url(#filter1_ibebebe)">
                                        <ellipse cx="147.894" cy="147.625" rx="48.7373" ry="49.1804" fill="url(#paint2_linearbebebe)"/>
                                    </g>
                                    <path d="M165.827 155.438C164.014 159.463 160.924 162.773 157.035 164.855C153.146 166.937 148.675 167.675 144.317 166.954C139.959 166.232 135.956 164.092 132.93 160.865C129.903 157.639 128.023 153.505 127.579 149.107C127.136 144.708 128.154 140.29 130.477 136.538C132.8 132.785 136.297 129.908 140.425 128.353C144.554 126.797 149.084 126.65 153.313 127.935C157.541 129.219 161.232 131.863 163.813 135.457L147.536 147.146L165.827 155.438Z" fill="#101527"/>
                                </g>
                                <path d="M43 153.006C23 153.406 6.33333 167.84 0.5 175.006C-0.666667 180.84 -3 192.906 -3 194.506C-3 196.506 16 149.506 43 156.006C70 162.506 81 193.506 116 185.006C127.877 182.122 137.164 181.195 145.5 181.425L143.5 179L144.5 176L143 174.5L147.5 170.961C137.646 171.287 127.125 172.573 116 175.006C84 182.006 68 152.506 43 153.006Z" fill="#101527"/>
                                <path d="M247.5 183.13C224 191.13 217 185.63 196.5 178.13C183.733 173.459 169.946 170.706 152.5 171L148.5 174.5L149.5 175.5L148 178.5L150 181.5C165.291 182.267 176.235 186.963 196.5 190.13C228.5 195.13 218 188.63 247.5 185.13C271.1 182.33 291.667 198.296 299 206.63V188.63C289.667 184.13 266.3 176.729 247.5 183.13Z" fill="#101527"/>
                            </g>
                            <defs>
                                <filter id="filter0_ibebebe" x="0" y="0" width="296" height="304" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="8"/>
                                    <feGaussianBlur stdDeviation="17.5"/>
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.389035 0 0 0 0 0.499495 0 0 0 0 0.629167 0 0 0 0.15 0"/>
                                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                                </filter>
                                <filter id="filter1_ibebebe" x="99.1562" y="98.4448" width="101.503" height="103.732" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="4.0284" dy="5.3712"/>
                                    <feGaussianBlur stdDeviation="2.6856"/>
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.876 0 0 0 0 0.89717 0 0 0 0 0.9125 0 0 0 0.25 0"/>
                                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                                </filter>
                                <linearGradient id="paint0_linearbebebe" x1="199.214" y1="115.487" x2="199.214" y2="139.195" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FFD600"/>
                                    <stop offset="1" stop-color="#E7C200"/>
                                </linearGradient>
                                <linearGradient id="paint1_linearbebebe" x1="96.4226" y1="115.487" x2="96.4226" y2="139.195" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FFD600"/>
                                    <stop offset="1" stop-color="#E7C200"/>
                                </linearGradient>
                                <linearGradient id="paint2_linearbebebe" x1="120.867" y1="117.94" x2="177.579" y2="191.489" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#009DFA"/>
                                    <stop offset="1" stop-color="#007DC7"/>
                                </linearGradient>
                            </defs>
            </svg>
        );
    }
});

export { View };