import React from 'react';
import { useSelector } from 'react-redux';

const View = React.memo(() => {
    const color = useSelector(state => state.colorSite);
	if (color === 'white') {
        return (
            <svg id="eg_2" className="white_eg_2" width="188" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id='blockDiagram'>
                    <rect x="31.5" y="123" width="21" height="43" rx="4" fill="url(#paint10_linear)"/>
                    <path opacity="0.3" d="M33.5 131.286V136C33.9595 131.286 39.0135 128.667 43.6081 128.667C47.2838 128.667 49.7342 129.714 50.5 130.238C50.5 125.524 46.8243 125 44.0676 125H39.0135C34.2351 125 33.5 128.928 33.5 131.286Z" fill="white"/>
                </g>
                <g id='blockDiagram'>
                    <path opacity="0.3" d="M65.5 114V118.5C66 114 71.5 111.5 76.5 111.5C80.5 111.5 83.1667 112.5 84 113C84 108.5 80 108 77 108H71.5C66.3 108 65.5 111.75 65.5 114Z" fill="white"/>
                    <rect x="63.5" y="107" width="21" height="59" rx="4" fill="url(#paint11_linear)"/>
                    <path opacity="0.3" d="M64.5 115.286V120C64.9865 115.286 70.3378 112.667 75.2027 112.667C79.0946 112.667 81.6892 113.714 82.5 114.238C82.5 109.524 78.6081 109 75.6892 109H70.3378C65.2784 109 64.5 112.928 64.5 115.286Z" fill="white"/>
                </g>
                <g id='blockDiagram'>
                    <rect x="95.5" y="85" width="21" height="81" rx="4" fill="url(#paint12_linear)"/>
                    <path opacity="0.3" d="M97.5 93.2857V98C97.9865 93.2857 103.338 90.6667 108.203 90.6667C112.095 90.6667 114.689 91.7143 115.5 92.2381C115.5 87.5238 111.608 87 108.689 87H103.338C98.2784 87 97.5 90.9281 97.5 93.2857Z" fill="white"/>
                </g>
                <g id='blockDiagram'>
                    <path fillRule="evenodd" clipRule="evenodd" d="M140.738 46.4665C139.141 44.2638 135.859 44.2638 134.262 46.4665L118.72 67.9021C116.802 70.5466 118.692 74.25 121.958 74.25H126.5V162C126.5 164.209 128.291 166 130.5 166H144.5C146.709 166 148.5 164.209 148.5 162V74.25H153.042C156.308 74.25 158.198 70.5466 156.28 67.9021L140.738 46.4665Z" fill="url(#paint13_linear)"/>
                    <path opacity="0.3" d="M125.584 62.406C125.545 62.4519 125.516 62.4836 125.5 62.5C125.528 62.4685 125.556 62.4371 125.584 62.406C126.336 61.529 131.138 55.4491 134.787 50.8142C135.942 49.3467 138.141 49.2798 139.385 50.6736L153.5 66.5C148.182 60.6837 135.176 51.7523 125.584 62.406Z" fill="white"/>
                    <rect x="20.5" y="171" width="9.99999" height="137" rx="4" transform="rotate(-90 20.5 171)" fill="white"/>
                </g>
                <g id='line_eg_2'>
                    <path d="M36 159C27.1667 153.333 12.5 139.469 12.5 121C12.5 88.5 44.5409 84.9508 59 97C65 102 68.4815 109.35 63.5 115.5C58.3679 121.836 45.4609 119.969 43 107.5C37.375 79 79 77 89 56" stroke="url(#paint14_linear)" strokeWidth="3" strokeLinecap="round" strokeDasharray="5.2 5.2"/>
                    <path id='circle_eg_2' d="M112.962 47.524C112.927 50.6275 111.868 53.6301 109.948 56.0662C108.028 58.5023 105.356 60.2357 102.345 60.9976C99.3337 61.7594 96.1525 61.5072 93.2945 60.28C90.4366 59.0528 88.0616 56.9192 86.5379 54.2101C85.0141 51.501 84.4269 48.3679 84.8672 45.2965C85.3075 42.2252 86.7507 39.3874 88.973 37.2231C91.1953 35.0589 94.0725 33.6893 97.1584 33.3267C100.244 32.964 103.366 33.6286 106.04 35.2175L98.8439 47.3295L112.962 47.524Z" fill="#101527"/>
                </g>
                
                <defs>
                    <linearGradient id="paint10_linear" x1="42" y1="123" x2="42" y2="166" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E2EDF3"/>
                        <stop offset="1" stopColor="#CFD9DE"/>
                    </linearGradient>
                    <linearGradient id="paint11_linear" x1="74" y1="107" x2="74" y2="166" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E2EDF3"/>
                        <stop offset="1" stopColor="#CFD9DE"/>
                    </linearGradient>
                    <linearGradient id="paint12_linear" x1="106" y1="85" x2="106" y2="166" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E2EDF3"/>
                        <stop offset="1" stopColor="#CFD9DE"/>
                    </linearGradient>
                    <linearGradient id="paint13_linear" x1="137.5" y1="44.8145" x2="137.5" y2="166" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E2EDF3"/>
                        <stop offset="1" stopColor="#CFD9DE"/>
                    </linearGradient>
                    <linearGradient id="paint14_linear" x1="50.75" y1="56" x2="50.75" y2="159" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FAFF11"/>
                        <stop offset="1" stopColor="#02E319"/>
                    </linearGradient>
                </defs>
            </svg>
        );
    }
    if (color === 'green') {
        return (
            <svg id="eg_2" class="green_eg_2" width="188" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id='blockDiagram'>
                                <rect x="31.5" y="123" width="21" height="43" rx="4" fill="url(#paint0_gglinear)"/>
                                <path opacity="0.3" d="M33.5 131.286V136C33.9595 131.286 39.0135 128.667 43.6081 128.667C47.2838 128.667 49.7342 129.714 50.5 130.238C50.5 125.524 46.8243 125 44.0676 125H39.0135C34.2351 125 33.5 128.928 33.5 131.286Z" fill="white"/>
                            </g>
                            <g id='blockDiagram'>
                                <path opacity="0.3" d="M65.5 114V118.5C66 114 71.5 111.5 76.5 111.5C80.5 111.5 83.1667 112.5 84 113C84 108.5 80 108 77 108H71.5C66.3 108 65.5 111.75 65.5 114Z" fill="white"/>
                                <rect x="63.5" y="107" width="21" height="59" rx="4" fill="url(#paint1_gglinear)"/>
                                <path opacity="0.3" d="M64.5 115.286V120C64.9865 115.286 70.3378 112.667 75.2027 112.667C79.0946 112.667 81.6892 113.714 82.5 114.238C82.5 109.524 78.6081 109 75.6892 109H70.3378C65.2784 109 64.5 112.928 64.5 115.286Z" fill="white"/>
                            </g>
                            
                            <g id='blockDiagram'>
                                <rect x="95.5" y="85" width="21" height="81" rx="4" fill="url(#paint2_gglinear)"/>
                                <path opacity="0.3" d="M97.5 93.2857V98C97.9865 93.2857 103.338 90.6667 108.203 90.6667C112.095 90.6667 114.689 91.7143 115.5 92.2381C115.5 87.5238 111.608 87 108.689 87H103.338C98.2784 87 97.5 90.9281 97.5 93.2857Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M140.738 46.4665C139.141 44.2638 135.859 44.2638 134.262 46.4665L118.72 67.9021C116.803 70.5466 118.692 74.25 121.958 74.25H126.5V162C126.5 164.209 128.291 166 130.5 166H144.5C146.709 166 148.5 164.209 148.5 162V74.25H153.042C156.308 74.25 158.198 70.5466 156.28 67.9021L140.738 46.4665Z" fill="url(#paint3_gglinear)"/>
                            </g>
                            <g id='blockDiagram'>
                                <path opacity="0.3" d="M125.584 62.406C125.545 62.4519 125.516 62.4836 125.5 62.5C125.528 62.4685 125.556 62.4371 125.584 62.406C126.336 61.529 131.138 55.4491 134.787 50.8142C135.942 49.3467 138.141 49.2798 139.385 50.6736L153.5 66.5C148.182 60.6837 135.176 51.7523 125.584 62.406Z" fill="white"/>
                                <rect x="20.5" y="171" width="9.99999" height="137" rx="4" transform="rotate(-90 20.5 171)" fill="white"/>
                            </g>
                            <g id='line_eg_2'>
                                <path d="M36 159C27.1667 153.333 12.5 139.469 12.5 121C12.5 88.5 44.5409 84.9508 59 97C65 102 68.4815 109.35 63.5 115.5C58.3679 121.836 45.4609 119.969 43 107.5C37.375 79 79 77 89 56" stroke="white" stroke-width="3" stroke-linecap="round" stroke-dasharray="5.2 5.2"/>
                                <path id='circle_eg_2' d="M112.962 47.524C112.927 50.6275 111.868 53.6301 109.948 56.0662C108.028 58.5023 105.356 60.2357 102.345 60.9976C99.3337 61.7594 96.1525 61.5072 93.2945 60.28C90.4366 59.0528 88.0616 56.9192 86.5379 54.2101C85.0141 51.501 84.4269 48.3679 84.8672 45.2965C85.3075 42.2252 86.7507 39.3874 88.973 37.2231C91.1953 35.0589 94.0725 33.6893 97.1584 33.3267C100.244 32.964 103.366 33.6286 106.04 35.2175L98.8439 47.3295L112.962 47.524Z" fill="#101527"/>
                            </g>
                            <defs>
                            <linearGradient id="paint0_gglinear" x1="42" y1="123" x2="42" y2="166" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FAFF11"/>
                            <stop stop-color="#FAFF11"/>
                            <stop offset="1" stop-color="#02E319"/>
                            </linearGradient>
                            <linearGradient id="paint1_gglinear" x1="74" y1="107" x2="74" y2="166" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FAFF11"/>
                            <stop stop-color="#FAFF11"/>
                            <stop offset="1" stop-color="#02E319"/>
                            </linearGradient>
                            <linearGradient id="paint2_gglinear" x1="106" y1="85" x2="106" y2="166" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FAFF11"/>
                            <stop stop-color="#FAFF11"/>
                            <stop offset="1" stop-color="#02E319"/>
                            </linearGradient>
                            <linearGradient id="paint3_gglinear" x1="137.5" y1="44.8145" x2="137.5" y2="166" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FAFF11"/>
                            <stop stop-color="#FAFF11"/>
                            <stop offset="1" stop-color="#02E319"/>
                            </linearGradient>
                            </defs>
                        </svg>
        );
    }
    if (color === 'blue') {
        return (
            <svg width="188" class="blue_eg_2" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id='blockDiagram'>
                            <rect x="31.5" y="123" width="21" height="43" rx="4" fill="url(#paint0_bblinear)"/>
                            <path opacity="0.3" d="M33.5 131.286V136C33.9595 131.286 39.0135 128.667 43.6081 128.667C47.2838 128.667 49.7342 129.714 50.5 130.238C50.5 125.524 46.8243 125 44.0676 125H39.0135C34.2351 125 33.5 128.928 33.5 131.286Z" fill="white"/>
                            </g>
                            <g id='blockDiagram'>
                            <path opacity="0.3" d="M65.5 114V118.5C66 114 71.5 111.5 76.5 111.5C80.5 111.5 83.1667 112.5 84 113C84 108.5 80 108 77 108H71.5C66.3 108 65.5 111.75 65.5 114Z" fill="white"/>
                            <rect x="63.5" y="107" width="21" height="59" rx="4" fill="url(#paint1_bblinear)"/>
                            <path opacity="0.3" d="M64.5 115.286V120C64.9865 115.286 70.3378 112.667 75.2027 112.667C79.0946 112.667 81.6892 113.714 82.5 114.238C82.5 109.524 78.6081 109 75.6892 109H70.3378C65.2784 109 64.5 112.928 64.5 115.286Z" fill="white"/>
                            </g>
                            <g id='blockDiagram'>
                            <rect x="95.5" y="85" width="21" height="81" rx="4" fill="url(#paint2_bblinear)"/>
                            <path opacity="0.3" d="M97.5 93.2857V98C97.9865 93.2857 103.338 90.6667 108.203 90.6667C112.095 90.6667 114.689 91.7143 115.5 92.2381C115.5 87.5238 111.608 87 108.689 87H103.338C98.2784 87 97.5 90.9281 97.5 93.2857Z" fill="white"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M140.738 46.4665C139.141 44.2638 135.859 44.2638 134.262 46.4665L118.72 67.9021C116.803 70.5466 118.692 74.25 121.958 74.25H126.5V162C126.5 164.209 128.291 166 130.5 166H144.5C146.709 166 148.5 164.209 148.5 162V74.25H153.042C156.308 74.25 158.198 70.5466 156.28 67.9021L140.738 46.4665Z" fill="url(#paint3_bblinear)"/>
                            </g>
                            <g id='blockDiagram'>
                            <path opacity="0.3" d="M125.584 62.406C125.545 62.4519 125.516 62.4836 125.5 62.5C125.528 62.4685 125.556 62.4371 125.584 62.406C126.336 61.529 131.138 55.4491 134.787 50.8142C135.942 49.3467 138.141 49.2798 139.385 50.6736L153.5 66.5C148.182 60.6837 135.176 51.7523 125.584 62.406Z" fill="white"/>
                            <rect x="20.5" y="171" width="9.99999" height="137" rx="4" transform="rotate(-90 20.5 171)" fill="#FFD600"/>
                            </g>
                            <g id='line_eg_2'>
                            <path d="M36 159C27.1667 153.333 12.5 139.469 12.5 121C12.5 88.5 44.5409 84.9508 59 97C65 102 68.4815 109.35 63.5 115.5C58.3679 121.836 45.4609 119.969 43 107.5C37.375 79 79 77 89 56" stroke="white" stroke-width="3" stroke-linecap="round" stroke-dasharray="5.2 5.2"/>
                            <path id='circle_eg_2' d="M112.962 47.524C112.927 50.6275 111.868 53.6301 109.948 56.0662C108.028 58.5023 105.356 60.2357 102.345 60.9976C99.3337 61.7594 96.1525 61.5072 93.2945 60.28C90.4366 59.0528 88.0616 56.9192 86.5379 54.2101C85.0141 51.501 84.4269 48.3679 84.8672 45.2965C85.3075 42.2252 86.7507 39.3874 88.973 37.2231C91.1953 35.0589 94.0725 33.6893 97.1584 33.3267C100.244 32.964 103.366 33.6286 106.04 35.2175L98.8439 47.3295L112.962 47.524Z" fill="#101527"/>
                            </g>
                            <defs>
                            <linearGradient id="paint0_bblinear" x1="36.1773" y1="131.523" x2="59.4421" y2="146.391" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#009DFA"/>
                            <stop offset="1" stop-color="#007DC7"/>
                            </linearGradient>
                            <linearGradient id="paint1_bblinear" x1="68.1773" y1="118.694" x2="95.1031" y2="131.236" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#009DFA"/>
                            <stop offset="1" stop-color="#007DC7"/>
                            </linearGradient>
                            <linearGradient id="paint2_bblinear" x1="100.177" y1="101.054" x2="129.562" y2="111.024" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#009DFA"/>
                            <stop offset="1" stop-color="#007DC7"/>
                            </linearGradient>
                            <linearGradient id="paint3_bblinear" x1="126.659" y1="68.8332" x2="178.436" y2="90.6941" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#009DFA"/>
                            <stop offset="1" stop-color="#007DC7"/>
                            </linearGradient>
                            </defs>
                        </svg>
        );
    }
});

export { View };