import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCookies } from '../../actions/actions';
import close from '../../assets/img/close.svg';
import arrow from '../../assets/img/arrow.svg';

import data from '../../assets/data.json';

const View = React.memo(() => {
    const [deta, setDeta] = useState(false);
    const visible = useSelector(state => state.cookies);
    const dispatch = useDispatch();
    const lang = useSelector(state => state.actualLang);
    useEffect(() => {
        var ASC = document.getElementById('allowSelectionsCookies');
        var AAC = document.getElementById('AllowAllCookies');

        ASC.addEventListener('click' || 'touchend', () => {
            if (openBtnCheckboxes.style.display === 'none') {
                localStorage.setItem('cookieCheck', 'true');
            } else {
                ASC.style.border = 'none';
                ASC.style.backgroundColor = '#ffffff';
                ASC.style.color = '#000000';
            }
        });
    });
	return (
		<div className="cookies" style={{display: (visible)? 'block' : 'none'}}>
        <div className="wrapper">
            <p>
                {data.lang[lang - 1].cookies[0]}
            </p>
            <img src={close} alt="close" className="closeCookies" onClick={() => {dispatch(setCookies(false));localStorage.setItem('cookieCheck', 'false');}}/>
            <div className="cookieManipulations">
                <div className="cookieSections">
                    <div className="cookieOpenSections" style={{display: (deta)?'none':'flex'}} onClick={() => setDeta(true)}><p>{data.lang[lang - 1].cookies[1]}</p><img src={arrow} alt="icon"/></div>
                    <div className="checkboxes" style={{display: (deta)?'flex':'none'}}>

                        <label className="container">{data.lang[lang - 1].cookies[2]}
                            <input type="checkbox" checked="checked" disabled/>
                            <span className="checkmark"></span>
                          </label>
                          
                          <label className="container">{data.lang[lang - 1].cookies[3]}
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                          </label>
                          
                          <label className="container">{data.lang[lang - 1].cookies[4]}
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                          </label>
                          
                          <label className="container">{data.lang[lang - 1].cookies[5]}
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                          </label> 

                    </div>
                </div>
                <div className="buttonsCookies">
                    <button id='allowSelectionsCookies' onClick={() => {dispatch(setCookies(false));localStorage.setItem('cookieCheck', 'false');}}>{data.lang[lang - 1].cookies[6]}</button>
                    <button id='AllowAllCookies' onClick={() => {dispatch(setCookies(false));localStorage.setItem('cookieCheck', 'false');}}>{data.lang[lang - 1].cookies[7]}</button>
                </div>
            </div>
        </div>
    </div>
        );
});

export { View };