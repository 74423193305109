import React from 'react';
import { useSelector } from 'react-redux';
import { Ee1, Ee2, Ee3 } from '../../components';
import data from '../../assets/data.json';

const View = React.memo(() => {
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
	return (
		<section className="block06">
            <div className="wrapper">
                <h2 style={{
                    color: (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#FFD600'
                }}>{data.lang[lang - 1].block6[0]}</h2>
                <div className="exp_list">
                    <div className="exp_elem">
                        <h4>{data.lang[lang - 1].block6[1]}</h4>
                        <Ee1/>
                        <h4>{data.lang[lang - 1].block6[2]}</h4>
                    </div>
                    <div className="exp_elem">
                        <h4>{data.lang[lang - 1].block6[3]}</h4>
                        <Ee2/>
                        <h4>{data.lang[lang - 1].block6[4]}</h4>
                    </div>
                    <div className="exp_elem">
                        <h4>{data.lang[lang - 1].block6[5]}</h4>
                        <Ee3/>
                        <h4>{data.lang[lang - 1].block6[6]}</h4>
                    </div>
                </div>
            </div>
        </section>
        );
});

export { View };