import React, {useEffect} from 'react';
import icon1 from './../../assets/img/ic1.png';
import icon2 from './../../assets/img/ic2.png';
import icon3 from './../../assets/img/ic3.png';
import icon4 from './../../assets/img/ic4.png';
import icon1g from './../../assets/img/ic1green.png';
import icon2g from './../../assets/img/ic2green.png';
import icon3g from './../../assets/img/ic3green.png';
import icon4g from './../../assets/img/ic4green.png';
import icon1b from './../../assets/img/ic1blue.png';
import icon2b from './../../assets/img/ic2blue.png';
import icon3b from './../../assets/img/ic3blue.png';
import icon4b from './../../assets/img/ic4blue.png';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal, setfromWhereAmI } from '../../actions/actions';
import data from '../../assets/data.json';

const View = React.memo(() => {
    useEffect(() => {
    });

    const dispatch = useDispatch();
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);

    var icn1 = (color == 'white') ? icon1 : (color == 'green')? icon1g : icon1b;
    var icn2 = (color == 'white') ? icon2 : (color == 'green')? icon2g : icon2b;
    var icn3 = (color == 'white') ? icon3 : (color == 'green')? icon3g : icon3b;
    var icn4 = (color == 'white') ? icon4 : (color == 'green')? icon4g : icon4b;
	return (
		<section id="implementation" className="block07">
        <div className="wrapper">
            <h2 style={{
                    color: (color == 'white') ? '#ffffff' : (color == 'green')? '#34C759' : '#009DFA'
                }}>{data.lang[lang - 1].block7[0]} <br/>{data.lang[lang - 1].block7[1]} <br className="mobBR"/>{data.lang[lang - 1].block7[2]}</h2>
            <div className="steps">
                <div className="step">
                    <img src={icn1} alt="icon"/>
                    <h4 style={{
                        color: (color == 'white') ? '#ffffff' : (color == 'green')? '#34C759' : '#FFD600'
                    }}>{data.lang[lang - 1].block7[3]}</h4>
                    <p>
                        {data.lang[lang - 1].block7[4]} 
                        <br/>
                        {data.lang[lang - 1].block7[5]} 
                        <br/>
                        {data.lang[lang - 1].block7[6]}
                    </p>
                </div>
                <div className="step">
                    <img src={icn2} alt="icon"/>
                    <h4 style={{
                        color: (color == 'white') ? '#ffffff' : (color == 'green')? '#34C759' : '#FFD600'
                    }}>{data.lang[lang - 1].block7[7]}</h4>
                    <p>
                        {data.lang[lang - 1].block7[8]} 
                        <br/>
                        {data.lang[lang - 1].block7[9]} 
                        <br/>
                        {data.lang[lang - 1].block7[10]}
                    </p>
                </div>
                <div className="step">
                    <img src={icn3} alt="icon"/>
                    <h4 style={{
                        color: (color == 'white') ? '#ffffff' : (color == 'green')? '#34C759' : '#FFD600'
                    }}>{data.lang[lang - 1].block7[11]}</h4>
                    <p>
                        {data.lang[lang - 1].block7[12]}
                        <br/>
                        {data.lang[lang - 1].block7[13]} 
                        <br/>
                        {data.lang[lang - 1].block7[14]}
                    </p>
                </div>
                <div className="step">
                    <img src={icn4} alt="icon"/>
                    <h4 style={{
                        color: (color == 'white') ? '#ffffff' : (color == 'green')? '#34C759' : '#FFD600'
                    }}>{data.lang[lang - 1].block7[15]}</h4>
                    <p>
                    {data.lang[lang - 1].block7[16]}
                        <br/>
                        {data.lang[lang - 1].block7[17]} 
                        <br/>
                        {data.lang[lang - 1].block7[18]}
                    </p>
                </div>
            </div>
            <button id='feedbackOpen3' style={{
                background: (color == 'white') ? '#ffffff' : (color == 'green')? 'linear-gradient(105.73deg, #FDFC47 3.91%, #3EEC55 88.01%)' : 'linear-gradient(142.62deg, #009DFA 20.88%, #007DC7 88.47%)',
                boxShadow: (color == 'white') ? 'none' : (color == 'green')? 'none' : 'none',
                color: (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#ffffff'
            }} onClick={() => {dispatch(setFeedbackModal(true));dispatch(setfromWhereAmI("thank_you_get_demo"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}}>{data.lang[lang - 1].block7[19]}</button>
        </div>
    </section>
        );
});

export { View };