import React from 'react';
import poster from './../../assets/img/poster.png';
import video from './../../assets/17.06.2021.mp4';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal, setfromWhereAmI } from '../../actions/actions';
import data from '../../assets/data.json';

const View = React.memo(() => {
    const dispatch = useDispatch();
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
	return (
		<section className="block03">
            <div className="wrapper">
                <div style={{position: "relative"}}>
                    <video preload="auto" poster={poster} controls>
                        <source src={video} type="video/mp4"/>
                    </video>
                </div>
                <button id='feedbackOpen' style={{
                    boxShadow: (color == 'white') ? '0px 13.502px 32.2548px rgba(184, 191, 210, 0.6)' : (color == 'green')? 'none' : 'none',
                }} onClick={() => {dispatch(setFeedbackModal(true));dispatch(setfromWhereAmI("thank_you_im_interested"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}}>{data.lang[lang - 1].block3}</button>
            </div>
        </section>
        );
});

export { View };