const initialState = {
    browserLang: null,
    // browserLang: "en-US",
    // actualLang: 1,
    fromWhereAmI: null,
    actualLang: (localStorage.getItem('lang') === "en-US") ? 1 : (localStorage.getItem('lang') === "ru-RU") ? 3 : 2,
    feedbackModal: false,
    cookies: (localStorage.getItem('cookieCheck') === 'false') ? false : true,
    successModal: false,
    successModalSlide: 1,
    headerModal: false,
    colorSite: 'white'
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };
  
    switch (action.type) {
        case "CHANGE_FEEDBACK_MODAL":
            return {
            ...state,
            feedbackModal: action.payload,
            };
        case "FROM_WHERE_AM_I":
            return {
            ...state,
            fromWhereAmI: action.payload,
            };
        case "CHANGE_COOKIES":
            return {
            ...state,
            cookies: action.payload,
            };
        case "CHANGE_SUCCESS_MODAL":
            return {
            ...state,
            successModal: action.payload,
            };
        case "CHANGE_HEADER_MODAL":
            return {
            ...state,
            headerModal: action.payload,
            };
        case "CHANGE_SUCCESS_MODAL_NUM":
            return {
            ...state,
            successModalSlide: action.payload,
            };
        case "CHANGE_COLOR_SITE":
            return {
            ...state,
            colorSite: action.payload,
            };
        case "CHANGE_BLANG":
            return {
                ...state,
                browserLang: action.payload,
            };
        case "CHANGE_LANG":
        return {
            ...state,
            actualLang: action.payload,
        };
        break;
    }
    return newState;
  };

export default reducer;