import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Eg1 } from '../../components';
import { Eg2 } from '../../components';
import { Eg3 } from '../../components';

import data from '../../assets/data.json';

const View = React.memo(() => {
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
	return (
		<section className="block02" id="solutions">
            <div className="wrapper">
                <h2 style={{color: (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#FFD600'}}>{data.lang[lang - 1].block2[0]}</h2>
                <div className="list">
                    <div className="easygrow_elem">
                        <Eg1/>
                        <h3>
                            <span style={{fontFamily: 'Futura PT Extra',textTransform: 'uppercase'}}>EasyGrow</span>
                            <br/>
                            Loyalty
                        </h3>
                        <p>
                            {data.lang[lang - 1].block2[1]}
                            <br/>
                            {data.lang[lang - 1].block2[2]}
                            <br/>
                            {data.lang[lang - 1].block2[3]}
                        </p>
                        <NavLink to='/loyalty' style={{color: (color == 'white') ? '#0073DD' : (color == 'green')? '#0073DD' : '#DDEFFF'}}>{data.lang[lang - 1].block2[10]}</NavLink>
                    </div>
                    <div className="easygrow_elem">
                        <Eg2/>
                        <h3>
                            <span style={{fontFamily: 'Futura PT Extra',textTransform: 'uppercase'}}>EasyGrow</span>
                            <br/>
                            Sales
                        </h3>
                        <p>
                            {data.lang[lang - 1].block2[4]}
                            <br/>
                            {data.lang[lang - 1].block2[5]}
                            <br/>
                            {data.lang[lang - 1].block2[6]}
                        </p>
                        <NavLink to='/sales' style={{color: (color == 'white') ? '#0073DD' : (color == 'green')? '#0073DD' : '#DDEFFF'}}>{data.lang[lang - 1].block2[10]}</NavLink>
                    </div>
                    <div className="easygrow_elem">
                        <Eg3/>
                        <h3 id="block02Translate6">
                            <span style={{fontFamily: 'Futura PT Extra',textTransform: 'uppercase'}}>EasyGrow</span>
                            <br/>
                            Learning
                        </h3>
                        <p id="block02Translate7">
                            {data.lang[lang - 1].block2[7]}
                            <br/>
                            {data.lang[lang - 1].block2[8]}
                            <br/>
                            {data.lang[lang - 1].block2[9]}
                        </p>
                        <NavLink to='/learning' style={{color: (color == 'white') ? '#0073DD' : (color == 'green')? '#0073DD' : '#DDEFFF'}}>{data.lang[lang - 1].block2[10]}</NavLink>
                    </div>
                </div>
            </div>
        </section>
        );
});

export { View };