import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFeedbackModal } from '../../actions/actions';
import close from '../../assets/img/closeFeedback.svg';
import arrow from '../../assets/img/arrowB.png';

import data from '../../assets/data.json';

const View = React.memo(( ) => {
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
    localStorage.setItem('fromWhereAmI', fromWhereAmI);
    useEffect(() => {
        var f_modal = document.getElementsByClassName('feedbackForm')[0];
        
        var langeForSend = (localStorage.getItem('lang') === undefined) ? 'en-EN' : (localStorage.getItem('lang') === '3') ? 'ru-RU' : (localStorage.getItem('lang') === '2') ? 'uk-UA' : 'en-EN';

        var inputsFeedbackModal = Array.prototype.slice.call(f_modal.getElementsByTagName('INPUT'));
        var placeholdersFeedbackModal = Array.prototype.slice.call(f_modal.getElementsByClassName('placeholder'));

        for( let i = 0; i < 4; i++ ) {
            if (placeholdersFeedbackModal[i] != undefined) {
            placeholdersFeedbackModal[i].style.transition = 'transform 0.3s linear';
            placeholdersFeedbackModal[i].style.transformOrigin = 'left top';
            if (inputsFeedbackModal[i].value.length > 0) {
                placeholdersFeedbackModal[i].style.transform = 'scale(0.7)';
            }
            placeholdersFeedbackModal[i].addEventListener('click' || 'ontouchend', () => {
                placeholdersFeedbackModal[i].style.transform = 'scale(0.7)';
                inputsFeedbackModal[i].focus();
            });
            inputsFeedbackModal[i].addEventListener('focus', () => {
                placeholdersFeedbackModal[i].style.transform = 'scale(0.7)';
            });
            inputsFeedbackModal[i].addEventListener('blur', () => {
                (inputsFeedbackModal[i].value.length <= 0) ? 
                placeholdersFeedbackModal[i].style.transform = 'scale(1)'
                :
                placeholdersFeedbackModal[i].style.transform = 'scale(0.7)';
            });
            }
        }

        inputsFeedbackModal[4].addEventListener('change', () => {
            f_modal.getElementsByClassName('container')[0].style.color = 'rgba(16, 21, 39, 0.5)';
            f_modal.getElementsByClassName('checkmark')[0].style.border = '1px solid rgba(16, 21, 39, 0.2)';
        });

        inputsFeedbackModal[5].addEventListener('change', () => {
            f_modal.getElementsByClassName('container')[1].style.color = 'rgba(16, 21, 39, 0.5)';
            f_modal.getElementsByClassName('checkmark')[1].style.border = '1px solid rgba(16, 21, 39, 0.2)';
        });

        function checkOutAlrt(index, alertText) {
            // if user start write in wrong input, clean alert
            inputsFeedbackModal[index].addEventListener('input', () => {
                f_modal.getElementsByClassName('input')[index].style.border = '1px solid rgba(16, 21, 39, 0.2)';
                f_modal.getElementsByClassName('alert')[index].style.display = 'none';
            });
            
            // checker
            if (inputsFeedbackModal[index].value.length <= 0) {
                f_modal.getElementsByClassName('input')[index].style.border = '1px solid red';
                f_modal.getElementsByClassName('alert')[index].innerText = alertText;
                f_modal.getElementsByClassName('alert')[index].style.display = 'block';
                return false;
            } else {
                return true;
            }
        }

        function validateEmail() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String( document.getElementById('emailInput').getElementsByTagName('INPUT')[0].value).toLowerCase());
        }

        f_modal.getElementsByTagName('BUTTON')[0].addEventListener('click' || 'ontouchend', () => {
            if (checkOutAlrt(2, 'Please, enter your email')) {
                if (!validateEmail()) {
                    f_modal.getElementsByClassName('input')[2].style.border = '1px solid red';
                    f_modal.getElementsByClassName('alert')[2].innerText = 'Please, enter valid email';
                    f_modal.getElementsByClassName('alert')[2].style.display = 'block';
                } else {
                    f_modal.getElementsByClassName('input')[2].style.border = '1px solid rgba(16, 21, 39, 0.2)';
                    f_modal.getElementsByClassName('alert')[2].style.display = 'none';
                    SendMail(inputsFeedbackModal[0].value + ' ' + inputsFeedbackModal[1].value, inputsFeedbackModal[3].value, inputsFeedbackModal[2].value,  fromWhereAmI, f_modal.getElementsByTagName('TEXTAREA')[0].value, langeForSend, window.location.href);
                }
            }
        });

        function SendMail(name, company, email, from, question, lang, page) {
            var dopInfo = '<br><b>Client data:</b><br>lang: ' + lang + '<br>page: ' + page + 
            '<br>ClientID: ' + localStorage.getItem('ClientID') +
            '<br>IsMobile: ' + localStorage.getItem('IsMobile') +
            '<br>FirstDateContact: ' + localStorage.getItem('FirstDateContact') +
            '<br>LastDateContact: ' + localStorage.getItem('LastDateContact') +
            '<br>FirstReferralSource: ' + localStorage.getItem('FirstReferralSource') +
            '<br>LastReferralSource: ' + localStorage.getItem('LastReferralSource') +
            '<br>ReferralUrl: ' + localStorage.getItem('ReferralUrl') +
            '<br>PageNumber: ' + localStorage.getItem('PageNumber');
            var xhr = new XMLHttpRequest();
            xhr.open("POST", 'https://easygrow.digitum.agency/EasyGrow/SendMail?name=' + name + '&from=' + localStorage.getItem('fromWhereAmI') + '&email=' + email, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            var roflanEbalo = question + '<br>' + dopInfo;
            xhr.send('"' + roflanEbalo + '"');
            history.pushState({}, null, localStorage.getItem('fromWhereAmI'));
            
            for( let i = 0; i < 5; i++ ) {
                inputsFeedbackModal[i].value = '';
                if (placeholdersFeedbackModal[i] != undefined) {placeholdersFeedbackModal[i].style.transform = 'scale(1)';}
            }
            f_modal.getElementsByTagName('TEXTAREA')[0].value = '';
        }
    });
    const visible = useSelector(state => state.feedbackModal);
    const lang = useSelector(state => state.actualLang);
    const [thanks, setThanks] = useState(false);
    const dispatch = useDispatch();

    return (
        <div className="feedbackForm" style={{display: (visible) ? 'block' : 'none'}}>
            <div className="afterFeedPart" style={{ display: ( thanks ) ? 'block' : 'none'}}>
                <img src={close} alt="icon" onClick={() => {
                    dispatch(setFeedbackModal(false));
                    if (window.location.href.includes('thank_you')) {history.back();}setThanks(!thanks);
                    document.getElementById("emailInput").style.border = '1px solid rgba(16, 21, 39, 0.2)';
                    document.getElementById("emailAlert").style.display = 'none';

            }} id="closeFeedbackNani"/>
                <p id="closeFeedback22"><img src={arrow} alt="exit" onClick={() => {
                    dispatch(setFeedbackModal(false));
                    if (window.location.href.includes('thank_you')) {history.back();}setThanks(!thanks);
                    document.getElementById("emailInput").style.border = '1px solid rgba(16, 21, 39, 0.2)';
                    document.getElementById("emailAlert").style.display = 'none';
            }}/>
                    <span>{data.lang[lang - 1].feedback[2]}</span>
                </p>
                <h2 style={{textAlign: 'center', paddingTop: '100px'}}>
                    <span>{data.lang[lang - 1].feedback[11]}</span>
                </h2>
                <p style={{fontFamily: 'Source Sans Pro', fontStyle: 'normal', fontWeight: '300', fontSize: '17px', lineHeight: '150%',textAlign: 'center', paddingBottom: '100px', color: 'rgba(16, 21, 39, 0.5)', paddingLeft: '25px',paddingRight: '25px'}}>{data.lang[lang - 1].feedback[12]}</p>
            </div>
            <div className="feedPart" style={{display: ( !thanks ) ? 'block' : 'none'}}>
            <img src={close} alt="icon" onClick={() => {dispatch(setFeedbackModal(false));if (window.location.href.includes('thank_you')) {history.back();}}} id="closeFeedback"/>
            <p id="closeFeedback2"><img src={arrow} alt="exit" onClick={() => {dispatch(setFeedbackModal(false));if (window.location.href.includes('thank_you')) {history.back();}}}/> 
                <span onClick={() => {dispatch(setFeedbackModal(false));if (window.location.href.includes('thank_you')) {history.back();}}}>{data.lang[lang - 1].feedback[2]}</span>
            </p>
            <h2><span>{data.lang[lang - 1].feedback[0]} <br/>{data.lang[lang - 1].feedback[1]}</span></h2>
            <div className="input">
                <p className="placeholder">{data.lang[lang - 1].feedback[3]}</p>
                <input type="text"/>
                <p className="alert"></p>
            </div>
            <div className="input" style={{display: 'none'}}>
                <p className="placeholder" style={{display: 'none'}} value='Anderson'>{data.lang[lang - 1].feedback[4]}</p>
                <input type="text"/>
                <p className="alert"></p>
            </div>
            <div className="input" id='emailInput'>
                <p className="placeholder">{data.lang[lang - 1].feedback[5]}</p>
                <input type="email"/>
                <p className="alert" id='emailAlert'></p>
            </div>
            <div className="input" style={{display: 'none'}}>
                <p className="placeholder" style={{display: 'none'}} value='Anderson'>{data.lang[lang - 1].feedback[6]}</p>
                <input type="text"/>
                <p className="alert"></p>
            </div>
            <div className="input h100" >
                <textarea placeholder={data.lang[lang - 1].feedback[7]}></textarea>
            </div>
            <div className="checkboxes" style={{display: 'none'}}>
                <label className="container">
                    I accept <a href="./privacy_policy.html" target="_blank">Privacy Policy</a>
                    <input type="checkbox" checked="checked"/>
                    <span className="checkmark"></span>
                </label>
                
                <label className="container">I agree to receive marketing information from Digitum
                    <input type="checkbox" checked="checked"/>
                    <span className="checkmark"></span>
                </label>
            </div>
            <button onClick={() => {
                if (document.getElementById('emailInput').getElementsByTagName('INPUT')[0].value === '') {
                    setThanks(!thanks);
                }
            }}>{data.lang[lang - 1].feedback[10]}</button>
            <p className="newFixes">
                Submiting I accept <a href="./privacy_policy.html" target="_blank">Privacy Policy</a> and agree to receive emails from Digitum
            </p>
            </div>
        </div>
        );
});

export { View };