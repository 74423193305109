import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal, setfromWhereAmI } from '../../actions/actions';
import { Blue_bird, Green_bird, White_bird } from '../../components';
import data from '../../assets/data.json';

const View = React.memo(() => {
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
    const dispatch = useDispatch();
    useEffect(() => {
        document. addEventListener("mousemove", (event) => {
            let white = document.getElementById('white_tilo');
            let blue = document.getElementById('blue_tilo');    
            let green = document.getElementById('green_tilo');
        
            let mousex = event.clientX;
            let mousey = event.clientY;
            if (white != null) {
                white.onmousemove = function() {
                    let x = (white.clientWidth / 2 - mousex) / 7;
                    let y = (white.clientWidth / 2 - mousey) / 7;
            
                    white.style.transform = 'translate(' + y + 'px,'+ x +'px)';
                }
                white.onmouseleave = function() {
                    white.style.transform = 'translate(0, 0)';
                }
            }
            if (blue != null) {
                blue.onmousemove = function() {
                    let x = (blue.clientWidth / 2 - mousex) / 7;
                    let y = (blue.clientWidth / 2 - mousey) / 7;
                    
                    blue.style.transform = 'translate(' + (-y) + 'px,'+ (y) +'px)';
                }
                blue.onmouseleave = function() {
                    blue.style.transform = 'translate(0, 0)';
                }
            }
            if (green != null) {
                green.onmousemove = function() {
                    let x = (green.clientWidth / 2 - mousex) / 7;
                    let y = (green.clientWidth / 2 - mousey) / 7;
                    
                    green.style.transform = 'translate(' + y + 'px,'+ y +'px)';
                }
                green.onmouseleave = function() {
                    green.style.transform = 'translate(0, 0)';
                }
            }
        });
    });
	return (
		<section className="block01">
            <div className="wrapper">

                <div className="leftPart">
                    <h1 style={{filter: (color == 'white') ? 'invert(0)' : (color == 'green')? 'invert(0)' : 'invert(1)'}}>{data.lang[lang - 1].block1[0]}</h1>
                    <p style={{filter: (color == 'white') ? 'invert(0)' : (color == 'green')? 'invert(0)' : 'invert(1)'}}>{data.lang[lang - 1].block1[1]}</p>
                    <button id='feedbackOpen2' style={{
                        backgroundColor: (color == 'white') ? '#ffffff' : (color == 'green')? '#101527' : '#E7C200',
                        boxShadow: (color == 'white') ? '0px 13.502px 32.2548px rgba(184, 191, 210, 0.6)' : (color == 'green')? 'none' : 'none',
                        color: (color == 'white') ? '#000000' : (color == 'green')? '#ffffff' : '#101527'
                    }} onClick={() => {dispatch(setFeedbackModal(true));dispatch(setfromWhereAmI("thank_you_get_a_proposal"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}}>{data.lang[lang - 1].block1[2]}</button>
                </div>

                <div className="rightPart">
                    <Blue_bird/>
                    <Green_bird/>
                    <White_bird/>
                </div>

                <button onClick={() => {dispatch(setFeedbackModal(true));dispatch(setfromWhereAmI("thank_you_get_a_proposal"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}} className="mobile_btn_b1">{data.lang[lang - 1].block1[2]}</button>
            </div>
        </section>
        );
});

export { View };