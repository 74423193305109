import React from "react";
import { useSelector } from 'react-redux';
import { LangSwitch } from './../switchLangs';
import data from '../../../assets/data.json';

const View = React.memo( () => {
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    return(
        <ul className='navigation' style={{filter: (color == 'white') ? 'invert(0)' : (color == 'green')? 'invert(0)' : 'invert(1)'}}>
            <li><a onClick={ () => {window.open('/#solutions', '_self');
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'PageView',
                pagePath: '/solutions',
                pageTitle: 'Solutions',
                page: '/solutions',
            });
            // ReactGA('set', 'page', '/solutions');
            // history.pushState({}, null, window.location.protocol + "//" + window.location.host + window.location.pathname + 'solutions');
            } } style={{cursor: 'pointer'}}>{data.lang[lang - 1].header_nav.solution}</a></li>
            <li><a onClick={ () => {window.open('/#implementation', '_self');
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'PageView',
                pagePath: '/implementation',
                pageTitle: 'Implementation',
                page: '/implementation',
            });
            // ReactGA('set', 'page', '/implementation');
            // history.pushState({}, null, window.location.protocol + "//" + window.location.host + window.location.pathname + 'implementation');
            } } style={{cursor: 'pointer'}}>{data.lang[lang - 1].header_nav.implementation}</a></li>
            <li><a onClick={ () => {window.open('/#our_cases', '_self');
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'PageView',
                    pagePath: '/our_cases',
                    pageTitle: 'Our_cases',
                    page: '/our_cases',
                });
            // ReactGA('set', 'page', '/our_cases');
            // history.pushState({}, null, window.location.protocol + "//" + window.location.host + window.location.pathname + 'our_cases');
            } } style={{cursor: 'pointer'}}>{data.lang[lang - 1].header_nav.our_cases}</a></li>
            <li><a onClick={ () => {window.open('/#about_us', '_self');
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'PageView',
                pagePath: '/about_us',
                pageTitle: 'About_us',
                page: '/about_us',
            });
            // ReactGA('set', 'page', '/about_us');
            // history.pushState({}, null, window.location.protocol + "//" + window.location.host + window.location.pathname + 'about_us');
            } } style={{cursor: 'pointer'}}>{data.lang[lang - 1].header_nav.about_us}</a></li>
            <LangSwitch/>
        </ul>
    );
});

export { View };