import {
	Main,
	Loyalty,
	Learning,
	Sales
} from './../pages';

// some usefull shit
const routes = {
	'main': {
		path: '/',
		page: Main,
		name: 'Main',
		link () {
			return this.path;
		},
	},
	'loyalty': {
		path: '/loyalty',
		page: Loyalty,
		name: 'Solutions',
		link () {
			return this.path;
		},
	},
	'sales': {
		path: '/sales',
		page: Sales,
		name: 'Sales',
		link () {
			return this.path;
		},
	},
	'learning': {
		path: '/learning',
		page: Learning,
		name: 'Learning',
		link () {
			return this.path;
		},
	}
};


const __ROOT_ROUTE__ = routes.main.link();

export { routes, __ROOT_ROUTE__ };