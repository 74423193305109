import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSuccessModal, setSuccessModalNum } from '../../actions/actions';
import close from '../../assets/img/close_black.svg';
import case1 from '../../assets/img/case1.png';
import case2 from '../../assets/img/case2.png';
import case3 from '../../assets/img/case3.png';
import prev from '../../assets/img/prev.png';
import next from '../../assets/img/next.png';
import point from '../../assets/img/point.png';

import data from '../../assets/data.json';

const View = React.memo(() => {
    const visible = useSelector(state => state.successModal);
    const slideNum = useSelector(state => state.successModalSlide);
    const color = useSelector(state => state.colorSite);
    const dispatch = useDispatch();
    const lang = useSelector(state => state.actualLang);
	return (
		<div className="succesfullModal" style={{display: (visible) ? 'flex' : 'none'}}>
        <div className="slides">
            <div className="slider">
                <img src={close} alt="icon" id="closeSlideModal" onClick={() => dispatch(setSuccessModal(false))}/>
                <div className="slide_container">
                    <div className="slide" style={{display: (slideNum === 1) ? 'block' : 'none', 
                        background: (color == 'white') ? '#ffffff' : (color == 'green')? 'linear-gradient(105.73deg, #FDFC47 3.91%, #3EEC55 88.01%)' : 'linear-gradient(142.62deg, #009DFA 20.88%, #007DC7 88.47%)'
                    }}>
                        <img src={case1} alt="image"/>
                    </div>
                    <div className="slide" style={{display: (slideNum === 2) ? 'block' : 'none', 
                        background: (color == 'white') ? '#ffffff' : (color == 'green')? 'linear-gradient(105.73deg, #FDFC47 3.91%, #3EEC55 88.01%)' : 'linear-gradient(142.62deg, #009DFA 20.88%, #007DC7 88.47%)'}}>
                        <img src={case2} alt="image"/>
                    </div>
                    <div className="slide" style={{display: (slideNum === 3) ? 'block' : 'none', 
                        background: (color == 'white') ? '#ffffff' : (color == 'green')? 'linear-gradient(105.73deg, #FDFC47 3.91%, #3EEC55 88.01%)' : 'linear-gradient(142.62deg, #009DFA 20.88%, #007DC7 88.47%)'}}>
                        <img src={case3} alt="image"/>
                    </div>
                </div>
                <img src={prev} onClick={() => {
                    if (slideNum === 1) {
                        dispatch(setSuccessModalNum(3));
                    }
                    if (slideNum === 2) {
                        dispatch(setSuccessModalNum(1));
                    }
                    if (slideNum === 3) {
                        dispatch(setSuccessModalNum(2));
                    }
                }} alt="icon" id="prev"/>
                <img src={next} onClick={() => {
                    if (slideNum === 1) {
                        dispatch(setSuccessModalNum(2));
                    }
                    if (slideNum === 2) {
                        dispatch(setSuccessModalNum(3));
                    }
                    if (slideNum === 3) {
                        dispatch(setSuccessModalNum(1));
                    }
                }} alt="icon" id="next"/>
            </div>
        </div>
        <div className="textPart">
            <div className="text" style={{display: (slideNum === 1) ? 'block' : 'none'}}>
                <h2>
                    {data.lang[lang - 1].success[0]}
                </h2>
                <p>
                    {data.lang[lang - 1].success[1]}
                </p>
                <ul>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[2]}
                        </p>
                    </li>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[3]}
                        </p>
                    </li>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[4]}
                        </p>
                    </li>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[5]}
                        </p>
                    </li>
                </ul>
            </div>
            <div className="text" style={{display: (slideNum === 2) ? 'block' : 'none'}}>
                <h2>
                {data.lang[lang - 1].success[6]}
                </h2>
                <p>
                {data.lang[lang - 1].success[7]}
                </p>
                <ul>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[8]}
                        </p>
                    </li>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[9]}
                        </p>
                    </li>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[10]}
                        </p>
                    </li>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[11]}
                        </p>
                    </li>
                </ul>
            </div>
            <div className="text" style={{display: (slideNum === 3) ? 'block' : 'none'}}>
                <h2>
                {data.lang[lang - 1].success[12]}
                </h2>
                <p>
                {data.lang[lang - 1].success[13]}
                </p>
                <ul>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[14]}
                        </p>
                    </li>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[15]}
                        </p>
                    </li>
                    <li>
                        <img src={point} width="8px" height="8px" alt="icon"/>
                        <p>
                        {data.lang[lang - 1].success[16]}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
        );
});

export { View };