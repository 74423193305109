import React, {useEffect} from 'react';

import DC1 from '../../assets/img/imgs02.png';
import DC2 from '../../assets/img/imgs03.png';
import DC3 from '../../assets/img/imgs01.png';
import MDC1 from '../../assets/img/case1.png';
import MDC2 from '../../assets/img/case2.png';
import MDC3 from '../../assets/img/case3.png';

import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal, setfromWhereAmI, setSuccessModal, setSuccessModalNum } from '../../actions/actions';

import data from '../../assets/data.json';

const View = React.memo(() => {
    useEffect(() => {
    });
    const dispatch = useDispatch();
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
	return (
		<section id="our_cases" className="block08">
            <div className="wrapper">
                <h2 id="block08Translate1" style={{
                    color: (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#FFD600'
                }}>{data.lang[lang - 1].block8[0]}</h2>
                <div className="imgs">
                    <img id="desktopCase" onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(1));}} src={DC1} alt="image"/>
                    <img id="desktopCase" onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(2));}} src={DC2} alt="image"/>
                    <img id="desktopCase" onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(3));}} src={DC3} alt="image"/>
                    <img id="mobileCase" onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(1));}} width="100%" src={MDC1} alt="image"/>
                    <img id="mobileCase" onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(2));}} width="100%" src={MDC2} alt="image"/>
                    <img id="mobileCase" onClick={() => {dispatch(setSuccessModal(true));dispatch(setSuccessModalNum(3));}} width="100%" src={MDC3} alt="image"/>
                </div>
                <button id="openSuccessModal" style={{
                    boxShadow: (color == 'white') ? '0px 18px 43px rgba(184, 191, 210, 0.6)' : (color == 'green')? 'none' : 'none',
                    background: (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#FFD600',
                    color: (color == 'white') ? '#ffffff' : (color == 'green')? '#ffffff' : '#101527'
                }} onClick={() => {dispatch(setFeedbackModal(true));dispatch(setfromWhereAmI("thank_you_get_in_touch"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}}>{data.lang[lang - 1].block8[1]}</button>
            </div>
        </section>
        );
});

export { View };