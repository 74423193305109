export const setFeedbackModal = (feedbackModal) => {
    return {
        type: "CHANGE_FEEDBACK_MODAL",
        payload: feedbackModal
    }
}

export const setCookies = (cookies) => {
    return {
        type: "CHANGE_COOKIES",
        payload: cookies
    }
}

export const setSuccessModal = (successModal) => {
    return {
        type: "CHANGE_SUCCESS_MODAL",
        payload: successModal
    }
}

export const setSuccessModalNum = (num) => {
    return {
        type: "CHANGE_SUCCESS_MODAL_NUM",
        payload: num
    }
}

export const setHeaderModal = (headerModal) => {
    return {
        type: "CHANGE_HEADER_MODAL",
        payload: headerModal
    }
}

export const setColorSite = (color) => {
    return {
        type: "CHANGE_COLOR_SITE",
        payload: color
    }
}

export const setBLang = (lang) => {
    return {
        type: "CHANGE_BLANG",
        payload: lang
    }
}

export const setLang = (lang) => {
    return {
        type: "CHANGE_LANG",
        payload: lang
    }
}

export const setfromWhereAmI = (value) => {
    return {
        type: "FROM_WHERE_AM_I",
        payload: value
    }
}