import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '../../../actions/actions';
import langArr from '../../../assets/img/langArr.png';
import './style.css';

const View = React.memo(() => {
    const l2 = useSelector(state => state.actualLang);
    const dispatch = useDispatch();
    const [openLang, setOpenLang] = useState(false);
    const [nameLang, setNameLang] = useState((l2 === 1) ? 'Eng' : (l2 === 2) ? 'Укр' : 'Руc');
	return (
        <>
            <ul class="lang" style={{display: openLang ? 'none' : 'block'}} onClick={() => {setOpenLang(!openLang)}}>
                <li><p>{nameLang}</p> <img src={ langArr } width="24px" height="24px" alt="icon"/></li>
            </ul>
            <ul style={{display: openLang ? 'flex' : 'none'}} class="lang-active">
                <li id="uaLang" onClick={() => {setOpenLang(!openLang);setNameLang('Укр');localStorage.setItem('lang', 'uk-UA');dispatch(setLang(2));}}><p>Укр</p></li>
                <li id="enLang" onClick={() => {setOpenLang(!openLang);setNameLang('Eng');localStorage.setItem('lang', 'en-US');dispatch(setLang(1));}}><p>Eng</p></li>
                <li id="ruLang" onClick={() => {setOpenLang(!openLang);setNameLang('Руc');localStorage.setItem('lang', 'ru-RU');dispatch(setLang(3));}}><p>Руc</p></li>
            </ul>
        </>
	);
});

export { View };