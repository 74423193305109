import React from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderModal } from '../../../actions/actions';

const View = React.memo(() => {
    const dispatch = useDispatch();
	return (
		<div className="mobile_header_btn" onClick={() => dispatch(setHeaderModal(true))}>
            <span></span>
            <span></span>
            <span></span>
        </div>);
});

export { View };