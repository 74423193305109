import React from "react";
import { useDispatch } from 'react-redux';
import { setColorSite } from '../../../actions/actions';

const View = React.memo( () => {
    const dispatch = useDispatch();
    return(
        <svg id='green_tilo' onClick={() => dispatch(setColorSite('green'))} width="167" height="111" viewBox="0 0 167 111" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id='green_right_wing' d="M165.05 44.0013C166.071 46.5748 158.299 47.5356 152.648 45.9844C153.615 47.1461 157.841 48.2269 157.281 49.5253C156.72 50.8236 153.283 50.6659 150.947 49.598C148.702 48.5497 147.351 47.5484 145.6 45.8466C147.322 47.7765 150.159 50.7134 148.796 51.2665C147.432 51.8196 143.689 50.0496 139.561 44.1846C139.828 45.3376 140.852 47.4378 139.625 48.2177C138.398 48.9976 136.198 44.9691 136.05 42.4705C135.777 36.6777 141.857 32.719 147.034 35.4139C149.695 36.8028 152.229 38.4387 154.822 39.939C159.527 42.6522 164.635 42.3032 165.05 44.0013Z" fill="#101527"/>
            <path id='green_left_wing' d="M1.08671 44.6806C-0.0623408 47.1997 7.65186 48.549 13.3735 47.2832C12.3491 48.3949 8.07421 49.2624 8.56901 50.5873C9.06382 51.9121 12.5039 51.9269 14.8912 50.9775C17.1855 50.0431 18.585 49.1109 20.4194 47.499C18.6024 49.3402 15.6218 52.131 16.9558 52.7518C18.2897 53.3727 22.1172 51.7926 26.534 46.142C26.2099 47.2802 25.0814 49.3264 26.268 50.1669C27.4546 51.0073 29.8537 47.0942 30.1265 44.6061C30.6901 38.8343 24.8157 34.5757 19.5105 37.0076C16.7832 38.2612 14.1704 39.768 11.5058 41.1364C6.67006 43.6101 1.5858 43.0054 1.08671 44.6806Z" fill="#101527"/>
            <ellipse cx="83" cy="55.5" rx="55" ry="55.5" fill="url(#paint6_linear)"/>
            <path d="M103.238 64.3157C101.193 68.858 97.7049 72.5937 93.3161 74.9433C88.9272 77.293 83.8824 78.1253 78.964 77.3112C74.0456 76.4971 69.5286 74.082 66.1135 70.4406C62.6984 66.7992 60.576 62.1349 60.0756 57.1711C59.5752 52.2074 60.7246 47.2216 63.3457 42.987C65.9668 38.7524 69.913 35.5056 74.5723 33.7503C79.2317 31.995 84.3437 31.8292 89.1156 33.2787C93.8875 34.7281 98.0525 37.7118 100.965 41.7669L82.5972 54.9579L103.238 64.3157Z" fill="#101527"/>
            <defs>
                <linearGradient id="paint6_linear" x1="34" y1="8.5" x2="135.934" y2="81.8594" gradientUnits="userSpaceOnUse">
                <stop offset="0.0447088" stopColor="#FDFC47"/>
                <stop offset="1" stopColor="#3EEC55"/>
                </linearGradient>
            </defs>
        </svg>
    );
});

export { View };