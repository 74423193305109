import React from 'react';
import { Block1, Block2, Block3, Block4, Block5, Block6, Block7, Block8, Block9, Cookies, FeedbackForm, ModalHeader, SuccessfullModal } from '../../blocks';
import { Header, Footer, Func } from '../../components';
import { useSelector } from 'react-redux';

import whiteBG from '../../assets/img/whiteBG.png';
import whiteBG2 from '../../assets/img/whiteBG2.png';
import greenBG from '../../assets/img/greenBG.png';
import greenBG2 from '../../assets/img/greenBG2.png';
import blueBG from '../../assets/img/blueBG.png';
import blueBG2 from '../../assets/img/blueBG2.png';

const View = () => {
    const color = useSelector(state => state.colorSite);
    
    var url1 = (color == 'white') ? whiteBG : (color == 'green')? greenBG : blueBG;
    var url2 = (color == 'white') ? whiteBG2 : (color == 'green')? greenBG2 : blueBG2;
    return (
        <>
            <Func/>
            <div id="bg1" style={{backgroundImage: 'url(' + url1 + ')',backgroundSize: 'cover', backgroundPosition: 'right top'}}>
                <Header/>
                <Block1/>
                <Block2/>
                <Block3/>
                <Block4/>
                <Block5/>
                <Block6/>
            </div>
            <Block7/>
            <div id="bg2" style={{backgroundImage: 'url(' + url2 + ')', backgroundSize: 'cover', backgroundPosition: 'top left'}}>
                <Block8/>
                <Block9/>
            </div>
        <Footer/>
        <Cookies/>
        <FeedbackForm/>
        <ModalHeader/>
        <SuccessfullModal/>
        </>
    );
}

export { View };