import React from 'react';
import { useSelector } from 'react-redux';

const View = React.memo(() => {
    const color = useSelector(state => state.colorSite);
    if (color === 'white') {
        return (
            <svg id="eg_1" className="white_eg_1" width="188" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="eg_1_line" d="M91.5 171C126.846 171 155.5 142.346 155.5 107C155.5 71.6538 126.846 43 91.5 43C56.1538 43 27.5 71.6538 27.5 107C27.5 142.346 56.1538 171 91.5 171Z" stroke="url(#paint7_linear)" strokeWidth="3" strokeLinecap="round" strokeDasharray="5.2 5.2"/>
                <path id="eg_1_line2" d="M91.5002 165C123.533 165 149.5 139.033 149.5 107C149.5 74.9675 123.533 49 91.5002 49C59.4676 49 33.5 74.9675 33.5 107C33.5 139.033 59.4676 165 91.5002 165Z" fill="url(#paint8_linear)"/>
                <path opacity="0.3" d="M62.5 62.0001C41.3 75.2001 36.6667 97.1667 37 106.5C53.4 54.8999 98.8333 55.3333 119.5 62.0001C109.333 56.5001 83.7 48.8001 62.5 62.0001Z" fill="white"/>
                <path id="eg_1_line3" d="M92 154C117.681 154 138.5 133.181 138.5 107.5C138.5 81.8188 117.681 61 92 61C66.3188 61 45.5 81.8188 45.5 107.5C45.5 133.181 66.3188 154 92 154Z" fill="white"/>
                <path id="eg_1_line4" d="M92 142C111.054 142 126.5 126.554 126.5 107.5C126.5 88.4462 111.054 73 92 73C72.9462 73 57.5 88.4462 57.5 107.5C57.5 126.554 72.9462 142 92 142Z" fill="url(#paint9_linear)"/>
                <path id="eg_e" d="M110.665 115.195C108.924 119.061 105.955 122.24 102.22 124.24C98.4846 126.24 94.191 126.948 90.005 126.255C85.819 125.562 81.9745 123.507 79.0679 120.407C76.1613 117.308 74.355 113.338 73.9291 109.114C73.5032 104.889 74.4815 100.646 76.7123 97.0417C78.9431 93.4376 82.3017 90.6744 86.2672 89.1804C90.2328 87.6865 94.5836 87.5454 98.645 88.779C102.706 90.0126 106.251 92.552 108.73 96.0033L93.0972 107.23L110.665 115.195Z" fill="#101527"/>
                <g id='stars'>
                    <g id="star1" filter="url(#filter0_d)">
                        <path d="M45.1707 22.1812C45.3054 22.1139 45.4738 22.0802 45.6086 22.1139L54.2985 22.6859C54.9384 22.7196 55.5447 22.6186 56.1173 22.3494C56.6899 22.0802 57.1951 21.7101 57.5993 21.2054L62.9884 14.3748C63.0895 14.2402 63.2242 14.1393 63.3589 14.072C63.6284 13.971 63.9315 13.971 64.1673 14.1056C64.3357 14.2065 64.5715 14.3748 64.6725 14.7113L66.8282 23.1233C67.1313 24.3683 68.0407 25.3441 69.2533 25.8152L77.4043 28.8099C77.9095 29.0118 78.0106 29.4156 78.0106 29.6174C78.0442 29.7857 78.0105 30.2231 77.5727 30.5259L70.23 35.1694C69.1522 35.8424 68.4786 37.0201 68.4449 38.2987L68.1081 46.9799C68.0744 47.5183 67.7039 47.7538 67.5355 47.8211C67.3671 47.8884 66.9629 48.023 66.525 47.6866L59.8223 42.1346C58.8455 41.327 57.532 41.0578 56.2857 41.3943L47.8989 43.7497C47.3937 43.8843 47.0569 43.6151 46.9222 43.4805C46.7874 43.3459 46.5516 42.9758 46.7537 42.4711L49.9535 34.3955C50.4251 33.2178 50.2903 31.8719 49.583 30.8288L44.7665 23.5944C44.5644 23.2916 44.5981 22.9887 44.6318 22.8205C44.7328 22.5513 44.9349 22.3158 45.1707 22.1812Z" fill="white"/>
                    </g>
                    <g id="star2" filter="url(#filter1_d)">
                        <path d="M13.3262 53.3912C13.4273 53.3576 13.562 53.3239 13.6631 53.3239L20.2984 53.7613C20.7699 53.795 21.2752 53.694 21.6794 53.5258C22.1172 53.3239 22.4877 53.0211 22.7909 52.651L26.9 47.4355C26.9674 47.3345 27.0685 47.2672 27.1695 47.1999C27.3716 47.099 27.6074 47.1326 27.8095 47.1999C27.9442 47.2672 28.1126 47.4018 28.18 47.671L29.8304 54.0978C30.0661 55.04 30.7735 55.8139 31.6829 56.1504L37.914 58.4385C38.2845 58.573 38.3856 58.9095 38.3856 59.0441C38.3856 59.1787 38.3856 59.5152 38.0487 59.7507L32.4239 63.3174C31.6155 63.8222 31.0766 64.7307 31.0429 65.7065L30.7735 72.3352C30.7735 72.739 30.4703 72.9072 30.3356 72.9745C30.2009 73.0418 29.8977 73.1427 29.5609 72.8735L24.4413 68.6339C23.7003 68.0282 22.6898 67.7927 21.7467 68.0618L15.3472 69.8789C14.943 69.9798 14.7072 69.7779 14.6062 69.677C14.5051 69.576 14.303 69.3068 14.4714 68.903L16.9302 62.7454C17.3007 61.8369 17.166 60.8275 16.6271 60.0199L12.9221 54.5016C12.7537 54.2661 12.7873 54.0305 12.821 53.8959C12.9894 53.6604 13.1578 53.4585 13.3262 53.3912Z" fill="white"/>
                        <path d="M29.662 55.949C29.7293 55.9153 29.7967 55.949 29.8641 55.9826C30.3019 56.4537 30.8071 56.8239 31.4134 57.0257L33.7712 57.9006C33.8722 57.9342 33.9059 58.0352 33.8722 58.1025C33.8385 58.2034 33.7375 58.2371 33.6701 58.2034L31.3124 57.3286C30.6724 57.093 30.0998 56.6893 29.6283 56.1845C29.5609 56.1172 29.5609 56.0163 29.6283 55.949C29.6283 55.9826 29.6283 55.9826 29.662 55.949Z" fill="white"/>
                        <path d="M24.2055 52.4828C24.2728 52.4491 24.3402 52.4491 24.3739 52.5164C24.4413 52.5837 24.4749 52.6847 24.4076 52.752L23.8687 53.4586C23.4308 53.997 22.8919 54.4344 22.2519 54.7036C21.612 54.9728 20.9383 55.1074 20.2647 55.0737C20.1636 55.0737 20.0963 54.9728 20.0963 54.9055C20.0963 54.8045 20.1973 54.7372 20.2647 54.7372C20.9047 54.7709 21.5446 54.6699 22.1172 54.4007C22.6898 54.1316 23.195 53.7614 23.5992 53.2567L24.1381 52.5501C24.1381 52.4828 24.1718 52.4828 24.2055 52.4828Z" fill="white"/>
                        <path d="M16.1555 54.6029C16.2902 54.5356 16.3913 54.502 16.526 54.502C16.627 54.502 16.6944 54.5692 16.7281 54.6365C16.7281 54.7375 16.6607 54.8048 16.5934 54.8384C16.2565 54.8721 15.9534 55.1076 15.785 55.4441C15.6503 55.7806 15.6503 56.1507 15.8524 56.4535L17.7722 59.3136C18.5469 60.4577 18.7153 61.9382 18.1764 63.2505L16.8965 66.4471C16.7618 66.8172 16.8291 67.221 17.0986 67.5238C17.368 67.8267 17.7722 67.9276 18.1427 67.8267L21.4436 66.8845C22.7908 66.5144 24.2392 66.8172 25.317 67.6921L27.9778 69.8792C28.281 70.1484 28.6852 70.182 29.0557 70.0138C29.4262 69.8456 29.6619 69.5091 29.6619 69.1053L29.7967 65.6732C29.864 64.2936 30.605 63.015 31.7839 62.2411L34.6805 60.3904C34.8489 60.2895 34.9837 60.1212 35.0847 59.953C35.1184 59.852 35.2194 59.8184 35.3205 59.8857C35.4215 59.9193 35.4552 60.0203 35.3879 60.1212C35.2868 60.3567 35.0847 60.5586 34.8826 60.6932L31.986 62.5439C30.9082 63.2168 30.2345 64.3945 30.1672 65.7068L30.0324 69.1389C29.9988 69.6773 29.6956 70.1147 29.2241 70.3166C28.7525 70.5185 28.2136 70.4512 27.8094 70.1147L25.1486 67.9276C24.1718 67.12 22.8245 66.8172 21.5783 67.1873L18.2775 68.1295C17.7722 68.2641 17.2333 68.1295 16.8965 67.7257C16.5597 67.3219 16.4586 66.7836 16.627 66.3125L17.907 63.1159C18.3785 61.9046 18.2438 60.5586 17.5364 59.5155L15.6166 56.6554C15.3471 56.2517 15.3134 55.7806 15.5155 55.3432C15.6166 54.973 15.886 54.7375 16.1555 54.6029Z" fill="white"/>
                    </g>
                    <g id="star3" filter="url(#filter2_d)">
                        <path d="M9.7896 89.5437C9.85696 89.51 9.92433 89.51 9.99169 89.51L14.1346 89.7792C14.4377 89.8128 14.7408 89.7455 15.0103 89.611C15.2798 89.4764 15.5155 89.3081 15.7176 89.0726L18.2774 85.8087C18.3111 85.7414 18.3785 85.7078 18.4459 85.6741C18.5806 85.6068 18.7153 85.6405 18.85 85.6741C18.9511 85.7078 19.0521 85.8087 19.0858 85.9769L20.1299 90.0147C20.2647 90.6204 20.7025 91.0915 21.2751 91.2934L25.1822 92.7402C25.418 92.8412 25.4854 93.0431 25.4854 93.1104C25.4854 93.2113 25.4854 93.4132 25.2833 93.5478L21.7804 95.7686C21.2751 96.1051 20.9383 96.6434 20.9046 97.2491L20.7362 101.388C20.7362 101.657 20.5341 101.758 20.4668 101.792C20.3994 101.825 20.1973 101.893 19.9952 101.724L16.7954 99.0661C16.3239 98.6623 15.6839 98.5277 15.1113 98.696L11.1032 99.84C10.8674 99.9073 10.699 99.7727 10.6316 99.7054C10.5643 99.6381 10.4632 99.4699 10.5306 99.2344L12.08 95.3648C12.3157 94.7928 12.2484 94.1535 11.9116 93.6487L9.58751 90.183C9.48646 90.0484 9.48646 89.9138 9.52014 89.8128C9.58751 89.7119 9.65487 89.611 9.7896 89.5437Z" fill="white"/>
                        <path d="M19.9952 91.1587C20.0289 91.1251 20.0963 91.1587 20.13 91.1924C20.3994 91.4952 20.7362 91.6971 21.1067 91.8653L22.5887 92.4037C22.6561 92.4373 22.6561 92.471 22.6561 92.5383C22.6224 92.6056 22.5887 92.6056 22.5214 92.6056L21.0394 92.0672C20.6352 91.9326 20.2647 91.6634 19.9952 91.3606C19.9279 91.2933 19.9279 91.226 19.9952 91.1587Z" fill="white"/>
                        <path d="M16.5934 88.9719C16.6271 88.9719 16.6608 88.9719 16.6944 88.9719C16.7281 89.0056 16.7618 89.0729 16.7281 89.1065L16.3913 89.5439C16.1219 89.8804 15.785 90.1496 15.3808 90.3178C14.9767 90.4861 14.5725 90.5534 14.1346 90.5534C14.0672 90.5534 14.0336 90.4861 14.0336 90.4524C14.0336 90.3851 14.1009 90.3515 14.1346 90.3515C14.5388 90.3851 14.943 90.3178 15.2798 90.1496C15.6503 89.9814 15.9534 89.7458 16.1892 89.443L16.526 89.0056C16.5597 89.0056 16.5597 88.9719 16.5934 88.9719Z" fill="white"/>
                        <path d="M11.5747 90.3178C11.6421 90.2841 11.7431 90.2505 11.8105 90.2505C11.8778 90.2505 11.9115 90.2841 11.9115 90.3514C11.9115 90.4187 11.8778 90.4524 11.8105 90.4524C11.5747 90.486 11.4063 90.6206 11.3052 90.8225C11.2042 91.0244 11.2379 91.2599 11.3726 91.4618L12.5852 93.2452C13.0567 93.9854 13.1577 94.8939 12.8546 95.7015L12.0462 97.6867C11.9452 97.9223 12.0126 98.1578 12.181 98.3597C12.3494 98.5616 12.5852 98.6289 12.8209 98.5616L14.9092 97.9896C15.7513 97.754 16.6607 97.9559 17.3343 98.4943L18.9847 99.8738C19.1868 100.042 19.4226 100.076 19.6584 99.9748C19.8941 99.8738 20.0289 99.672 20.0289 99.4028L20.0962 97.2493C20.1299 96.3744 20.6015 95.5669 21.3425 95.0958L23.1613 93.9518C23.2623 93.8845 23.3634 93.7835 23.3971 93.6826C23.4307 93.6153 23.4981 93.6153 23.5318 93.6153C23.5991 93.6489 23.5991 93.7162 23.5991 93.7499C23.5318 93.8845 23.4307 94.0191 23.2623 94.12L21.4435 95.2641C20.7699 95.7015 20.332 96.4417 20.2983 97.2493L20.231 99.4028C20.231 99.7393 20.0289 100.008 19.7257 100.143C19.4226 100.278 19.0858 100.244 18.8163 100.008L17.1659 98.6289C16.5596 98.1241 15.7176 97.9223 14.9429 98.1578L12.8546 98.7298C12.5515 98.8308 12.2147 98.7298 11.9789 98.4943C11.7431 98.2587 11.7094 97.9223 11.8105 97.6194L12.6188 95.6342C12.922 94.8939 12.8209 94.0527 12.3831 93.3798L11.1705 91.5964C11.0021 91.3609 10.9684 91.0244 11.1032 90.7552C11.2379 90.5197 11.3726 90.3851 11.5747 90.3178Z" fill="white"/>
                    </g>
                    <g id="star4" filter="url(#filter3_d)">
                        <path d="M107.314 17.84C107.213 17.8737 107.112 17.941 107.044 18.0419L102.733 23.0891C102.43 23.4593 102.026 23.7285 101.588 23.9304C101.15 24.0986 100.645 24.1659 100.173 24.1322L93.5717 23.4256C93.4369 23.4256 93.3359 23.4256 93.2348 23.4593C93.0327 23.5266 92.8643 23.6948 92.797 23.9304C92.7633 24.0649 92.7296 24.3005 92.8643 24.536L96.2999 30.1889C96.8051 31.0301 96.8725 32.0396 96.4683 32.9481L93.7737 39.0048C93.6053 39.3749 93.8074 39.6777 93.8748 39.7787C93.9758 39.8796 94.2116 40.1152 94.6158 40.0142L101.083 38.4664C102.026 38.2309 103.036 38.5 103.744 39.1394L108.695 43.5809C108.998 43.8501 109.335 43.7828 109.469 43.7155C109.604 43.6482 109.907 43.5136 109.941 43.1099L110.48 36.4812C110.547 35.5054 111.12 34.6305 111.962 34.1594L117.722 30.8282C118.092 30.6264 118.092 30.2899 118.092 30.1553C118.092 30.0207 118.025 29.6842 117.654 29.516L111.524 26.9587C110.615 26.5886 109.975 25.781 109.773 24.8389L108.392 18.3447C108.324 18.0756 108.156 17.941 108.021 17.84C107.718 17.7727 107.482 17.7727 107.314 17.84Z" fill="white"/>
                        <path d="M97.7819 31.333C97.7145 31.3667 97.6809 31.434 97.6809 31.5012C97.7145 32.1406 97.6135 32.7799 97.344 33.3519L96.2999 35.6736C96.2662 35.7746 96.2999 35.8419 96.4009 35.9092C96.502 35.9428 96.5693 35.9092 96.6367 35.8082L97.6809 33.4865C97.9503 32.8472 98.085 32.1742 98.0514 31.5012C98.0514 31.4003 97.9503 31.333 97.8829 31.333C97.8156 31.333 97.7819 31.333 97.7819 31.333Z" fill="white"/>
                        <path d="M99.0618 24.974C98.9944 25.0076 98.9607 25.0413 98.9607 25.1086C98.9607 25.2095 99.0281 25.2768 99.0954 25.2768L99.9712 25.3778C100.645 25.445 101.352 25.3441 101.992 25.1086C102.632 24.8394 103.205 24.4356 103.643 23.9309C103.71 23.8636 103.71 23.7626 103.609 23.6953C103.541 23.628 103.44 23.628 103.373 23.729C102.969 24.2001 102.43 24.5702 101.857 24.8057C101.285 25.0413 100.645 25.1422 100.005 25.0749L99.1291 24.974C99.1291 24.974 99.0955 24.974 99.0618 24.974Z" fill="white"/>
                        <path d="M106.202 20.7005C106.068 20.7341 105.967 20.8014 105.866 20.9024C105.798 20.9696 105.798 21.0706 105.832 21.1379C105.899 21.2052 106 21.2052 106.068 21.1715C106.337 20.936 106.708 20.9024 107.044 21.0033C107.381 21.1379 107.617 21.4071 107.718 21.7436L108.425 25.1084C108.729 26.488 109.672 27.5984 110.952 28.1367L114.151 29.449C114.522 29.6173 114.758 29.9201 114.791 30.3239C114.825 30.7276 114.623 31.0978 114.286 31.266L111.288 32.9821C110.076 33.6887 109.301 34.9337 109.166 36.3133L108.897 39.8127C108.863 40.2164 108.628 40.5529 108.257 40.6875C107.887 40.8221 107.482 40.7548 107.179 40.4856L104.619 38.1976C103.575 37.2554 102.161 36.8853 100.78 37.2218L97.4114 38.0293C97.2094 38.063 97.0073 38.063 96.8052 37.9957C96.7041 37.962 96.6368 37.9957 96.6031 38.0966C96.5694 38.1976 96.6031 38.2649 96.7041 38.2985C96.9399 38.3994 97.2094 38.3994 97.4788 38.3321L100.847 37.5246C102.093 37.2218 103.407 37.5582 104.384 38.4331L106.943 40.7212C107.348 41.0577 107.887 41.1586 108.358 40.9904C108.83 40.7885 109.166 40.351 109.2 39.8463L109.47 36.4142C109.571 35.1356 110.312 33.9915 111.423 33.3522L114.421 31.6361C114.859 31.367 115.128 30.8959 115.094 30.3575C115.061 29.8528 114.724 29.3817 114.252 29.1798L111.053 27.8675C109.874 27.3628 108.998 26.3197 108.729 25.0747L108.021 21.7099C107.92 21.2388 107.583 20.8687 107.146 20.7005C106.842 20.5995 106.506 20.5995 106.202 20.7005Z" fill="white"/>
                    </g>
                    <g id="star5" filter="url(#filter4_d)">
                        <path d="M134.293 39.1397C134.226 39.1733 134.158 39.207 134.125 39.2743L131.43 42.4372C131.228 42.6727 130.992 42.841 130.723 42.9419C130.453 43.0429 130.15 43.1102 129.847 43.0765L125.704 42.6391C125.637 42.6391 125.536 42.6391 125.502 42.6727C125.367 42.7064 125.266 42.841 125.233 42.9756C125.199 43.0765 125.199 43.2111 125.266 43.3457L127.456 46.8788C127.759 47.3835 127.826 48.0565 127.557 48.5948L125.872 52.3971C125.771 52.6326 125.872 52.8009 125.94 52.8682C126.007 52.9355 126.142 53.0701 126.411 53.0364L130.453 52.0606C131.059 51.926 131.666 52.0606 132.137 52.498L135.236 55.2908C135.438 55.4591 135.64 55.3918 135.708 55.3918C135.775 55.3581 135.977 55.2572 136.011 54.988L136.348 50.8493C136.381 50.2436 136.752 49.7052 137.257 49.4024L140.861 47.3162C141.097 47.1816 141.097 46.9797 141.097 46.8788C141.097 46.7778 141.063 46.5759 140.827 46.475L136.886 44.8599C136.314 44.6243 135.91 44.1196 135.775 43.5476L134.899 39.4762C134.866 39.3079 134.764 39.207 134.663 39.1733C134.562 39.0724 134.394 39.0724 134.293 39.1397Z" fill="white"/>
                        <path d="M128.331 47.5513C128.298 47.5513 128.264 47.6186 128.264 47.6522C128.298 48.056 128.23 48.4598 128.062 48.7963L127.422 50.2431C127.388 50.3104 127.422 50.3441 127.489 50.3777C127.557 50.4114 127.59 50.3777 127.624 50.3104L128.264 48.8636C128.432 48.4598 128.5 48.0223 128.5 47.6186C128.466 47.5849 128.432 47.5513 128.331 47.5513Z" fill="white"/>
                        <path d="M129.14 43.5809C129.106 43.5809 129.072 43.6145 129.072 43.6818C129.072 43.7491 129.106 43.7828 129.173 43.7828L129.712 43.8501C130.15 43.8837 130.588 43.8501 130.958 43.6818C131.363 43.5136 131.699 43.278 132.003 42.9416C132.036 42.9079 132.036 42.8406 132.003 42.807C131.969 42.7733 131.902 42.7733 131.868 42.807C131.598 43.1098 131.295 43.3453 130.925 43.4799C130.554 43.6145 130.15 43.6818 129.78 43.6482L129.241 43.5809C129.173 43.5809 129.14 43.5809 129.14 43.5809Z" fill="white"/>
                        <path d="M133.586 40.9227C133.518 40.9564 133.451 40.99 133.384 41.0573C133.35 41.091 133.316 41.1583 133.384 41.1919C133.417 41.2256 133.485 41.2592 133.518 41.1919C133.687 41.0573 133.922 41.0237 134.125 41.091C134.327 41.1583 134.495 41.3265 134.529 41.562L134.967 43.6819C135.135 44.5231 135.741 45.2297 136.55 45.5662L138.537 46.4074C138.773 46.5083 138.907 46.7102 138.941 46.9458C138.941 47.1813 138.84 47.4168 138.604 47.5514L136.752 48.6282C136.011 49.0656 135.505 49.8395 135.438 50.7144L135.27 52.8679C135.236 53.1034 135.101 53.3389 134.866 53.4062C134.63 53.5072 134.394 53.4399 134.192 53.2716L132.575 51.8248C131.935 51.2527 131.026 51.0172 130.184 51.2191L128.095 51.7238C127.961 51.7575 127.826 51.7575 127.725 51.6902C127.658 51.6565 127.624 51.6902 127.59 51.7575C127.557 51.8248 127.59 51.8584 127.658 51.8921C127.826 51.9593 127.994 51.9593 128.163 51.9257L130.251 51.421C131.026 51.2191 131.868 51.4546 132.474 51.993L134.091 53.4399C134.327 53.6754 134.663 53.7091 134.967 53.6081C135.27 53.4735 135.472 53.2043 135.505 52.8679L135.674 50.7144C135.741 49.9068 136.179 49.2002 136.886 48.7964L138.739 47.7197C139.008 47.5514 139.177 47.2486 139.177 46.9121C139.143 46.5756 138.941 46.3064 138.638 46.1719L136.651 45.3306C135.91 45.0278 135.371 44.3548 135.202 43.5809L134.764 41.4611C134.697 41.1583 134.495 40.9227 134.226 40.8218C133.99 40.8554 133.788 40.8554 133.586 40.9227Z" fill="white"/>
                    </g>
                </g>
                <defs>
                    <filter id="filter0_d" x="35.6063" y="6" width="59.4139" height="59.9053" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="4" dy="5"/>
                        <feGaussianBlur stdDeviation="6.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <filter id="filter1_d" x="3.79352" y="39.1362" width="51.5921" height="51.914" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="4" dy="5"/>
                        <feGaussianBlur stdDeviation="6.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <filter id="filter2_d" x="0.5" y="77.6353" width="41.9854" height="42.1984" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="4" dy="5"/>
                        <feGaussianBlur stdDeviation="6.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <filter id="filter3_d" x="83.7668" y="9.78955" width="51.3252" height="51.9862" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="4" dy="5"/>
                        <feGaussianBlur stdDeviation="6.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <filter id="filter4_d" x="116.21" y="31.0928" width="41.8863" height="42.3123" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="4" dy="5"/>
                        <feGaussianBlur stdDeviation="6.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <linearGradient id="paint7_linear" x1="91.5" y1="43" x2="91.5" y2="171" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FAFF11"/>
                        <stop offset="1" stopColor="#02E319"/>
                    </linearGradient>
                    <linearGradient id="paint8_linear" x1="91.5002" y1="49" x2="91.5002" y2="165" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E2EDF3"/>
                        <stop offset="1" stopColor="#CFD9DE"/>
                    </linearGradient>
                    <linearGradient id="paint9_linear" x1="92" y1="73" x2="92" y2="142" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E2EDF3"/>
                        <stop offset="1" stopColor="#CFD9DE"/>
                    </linearGradient>
                </defs>
            </svg>
            );
    }
    if (color === 'green') {
        return (
            <svg id="eg_1" class="green_eg_1" width="188" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="eg_1_line" d="M91.5 171C126.846 171 155.5 142.346 155.5 107C155.5 71.6538 126.846 43 91.5 43C56.1538 43 27.5 71.6538 27.5 107C27.5 142.346 56.1538 171 91.5 171Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-dasharray="5.2 5.2"/>
                            <path id="eg_1_line2" d="M91.5002 165C123.533 165 149.5 139.033 149.5 107C149.5 74.9675 123.533 49 91.5002 49C59.4676 49 33.5 74.9675 33.5 107C33.5 139.033 59.4676 165 91.5002 165Z" fill="url(#paint0_glinear)"/>
                            <path opacity="0.3" d="M62.4998 62.0001C41.2998 75.2001 36.6664 97.1667 36.9998 106.5C53.3998 54.8999 98.8331 55.3333 119.5 62.0001C109.333 56.5001 83.6998 48.8001 62.4998 62.0001Z" fill="white"/>
                            <path id="eg_1_line3" d="M92 154C117.681 154 138.5 133.181 138.5 107.5C138.5 81.8188 117.681 61 92 61C66.3188 61 45.5 81.8188 45.5 107.5C45.5 133.181 66.3188 154 92 154Z" fill="white"/>
                            <path id="eg_1_line4" d="M92 142C111.054 142 126.5 126.554 126.5 107.5C126.5 88.4462 111.054 73 92 73C72.9462 73 57.5 88.4462 57.5 107.5C57.5 126.554 72.9462 142 92 142Z" fill="url(#paint1_glinear)"/>
                            <path id="eg_e" d="M110.665 115.195C108.924 119.061 105.956 122.24 102.22 124.24C98.4848 126.24 94.1912 126.948 90.0052 126.255C85.8192 125.562 81.9747 123.507 79.0681 120.407C76.1615 117.308 74.3552 113.338 73.9293 109.114C73.5034 104.889 74.4817 100.646 76.7125 97.0417C78.9433 93.4376 82.3019 90.6744 86.2675 89.1804C90.233 87.6865 94.5838 87.5454 98.6452 88.779C102.707 90.0126 106.251 92.552 108.73 96.0033L93.0974 107.23L110.665 115.195Z" fill="#101527"/>
                            <g id='stars'>
                                <g id="star1" filter="url(#filter0_gd)">
                                    <path d="M45.1708 22.1812C45.3055 22.1139 45.474 22.0802 45.6087 22.1139L54.2986 22.6859C54.9386 22.7196 55.5448 22.6186 56.1174 22.3494C56.69 22.0802 57.1953 21.7101 57.5994 21.2054L62.9885 14.3748C63.0896 14.2402 63.2243 14.1393 63.359 14.072C63.6285 13.971 63.9316 13.971 64.1674 14.1056C64.3358 14.2065 64.5716 14.3748 64.6726 14.7113L66.8283 23.1233C67.1314 24.3683 68.0408 25.3441 69.2534 25.8152L77.4044 28.8099C77.9096 29.0118 78.0107 29.4156 78.0107 29.6174C78.0444 29.7857 78.0107 30.2231 77.5728 30.5259L70.2302 35.1694C69.1523 35.8424 68.4787 37.0201 68.445 38.2987L68.1082 46.9799C68.0745 47.5183 67.704 47.7538 67.5356 47.8211C67.3672 47.8884 66.963 48.023 66.5251 47.6866L59.8224 42.1346C58.8457 41.327 57.5321 41.0578 56.2858 41.3943L47.8991 43.7497C47.3938 43.8843 47.057 43.6151 46.9223 43.4805C46.7875 43.3459 46.5518 42.9758 46.7539 42.4711L49.9536 34.3955C50.4252 33.2178 50.2905 31.8719 49.5831 30.8288L44.7666 23.5944C44.5645 23.2916 44.5982 22.9887 44.6319 22.8205C44.733 22.5513 44.935 22.3158 45.1708 22.1812Z" fill="url(#paint2_glinear)"/>
                                </g>
                                <g id="star2" filter="url(#filter1_gd)">
                                    <path d="M13.3267 53.3912C13.4277 53.3576 13.5624 53.3239 13.6635 53.3239L20.2988 53.7613C20.7704 53.795 21.2756 53.694 21.6798 53.5258C22.1176 53.3239 22.4881 53.0211 22.7913 52.651L26.9005 47.4355C26.9678 47.3345 27.0689 47.2672 27.1699 47.1999C27.372 47.099 27.6078 47.1326 27.8099 47.1999C27.9446 47.2672 28.113 47.4018 28.1804 47.671L29.8308 54.0978C30.0666 55.04 30.7739 55.8139 31.6833 56.1504L37.9145 58.4385C38.285 58.573 38.386 58.9095 38.386 59.0441C38.386 59.1787 38.386 59.5152 38.0492 59.7507L32.4243 63.3174C31.6159 63.8222 31.077 64.7307 31.0434 65.7065L30.7739 72.3352C30.7739 72.739 30.4708 72.9072 30.336 72.9745C30.2013 73.0418 29.8982 73.1427 29.5613 72.8735L24.4417 68.6339C23.7007 68.0282 22.6902 67.7927 21.7471 68.0618L15.3476 69.8789C14.9434 69.9798 14.7076 69.7779 14.6066 69.677C14.5055 69.576 14.3034 69.3068 14.4719 68.903L16.9306 62.7454C17.3011 61.8369 17.1664 60.8275 16.6275 60.0199L12.9225 54.5016C12.7541 54.2661 12.7878 54.0305 12.8214 53.8959C12.9899 53.6604 13.1583 53.4585 13.3267 53.3912Z" fill="url(#paint3_glinear)"/>
                                    <path d="M29.6623 55.949C29.7297 55.9153 29.7971 55.949 29.8644 55.9826C30.3023 56.4537 30.8075 56.8239 31.4138 57.0257L33.7715 57.9006C33.8726 57.9342 33.9063 58.0352 33.8726 58.1025C33.8389 58.2034 33.7378 58.2371 33.6705 58.2034L31.3127 57.3286C30.6728 57.093 30.1002 56.6893 29.6286 56.1845C29.5613 56.1172 29.5613 56.0163 29.6286 55.949C29.6286 55.9826 29.6286 55.9826 29.6623 55.949Z" fill="white"/>
                                    <path d="M24.2059 52.4828C24.2732 52.4491 24.3406 52.4491 24.3743 52.5164C24.4417 52.5837 24.4753 52.6847 24.408 52.752L23.8691 53.4586C23.4312 53.997 22.8923 54.4344 22.2523 54.7036C21.6124 54.9728 20.9387 55.1074 20.2651 55.0737C20.164 55.0737 20.0967 54.9728 20.0967 54.9055C20.0967 54.8045 20.1977 54.7372 20.2651 54.7372C20.905 54.7709 21.545 54.6699 22.1176 54.4007C22.6902 54.1316 23.1954 53.7614 23.5996 53.2567L24.1385 52.5501C24.1385 52.4828 24.1722 52.4828 24.2059 52.4828Z" fill="white"/>
                                    <path d="M16.1556 54.6029C16.2903 54.5356 16.3914 54.502 16.5261 54.502C16.6272 54.502 16.6945 54.5692 16.7282 54.6365C16.7282 54.7375 16.6608 54.8048 16.5935 54.8384C16.2567 54.8721 15.9535 55.1076 15.7851 55.4441C15.6504 55.7806 15.6504 56.1507 15.8525 56.4535L17.7723 59.3136C18.547 60.4577 18.7154 61.9382 18.1765 63.2505L16.8966 66.4471C16.7619 66.8172 16.8293 67.221 17.0987 67.5238C17.3682 67.8267 17.7723 67.9276 18.1428 67.8267L21.4437 66.8845C22.791 66.5144 24.2393 66.8172 25.3171 67.6921L27.978 69.8792C28.2811 70.1484 28.6853 70.182 29.0558 70.0138C29.4263 69.8456 29.6621 69.5091 29.6621 69.1053L29.7968 65.6732C29.8641 64.2936 30.6051 63.015 31.784 62.2411L34.6807 60.3904C34.8491 60.2895 34.9838 60.1212 35.0848 59.953C35.1185 59.852 35.2196 59.8184 35.3206 59.8857C35.4217 59.9193 35.4553 60.0203 35.388 60.1212C35.2869 60.3567 35.0848 60.5586 34.8827 60.6932L31.9861 62.5439C30.9083 63.2168 30.2346 64.3945 30.1673 65.7068L30.0326 69.1389C29.9989 69.6773 29.6957 70.1147 29.2242 70.3166C28.7526 70.5185 28.2137 70.4512 27.8096 70.1147L25.1487 67.9276C24.1719 67.12 22.8246 66.8172 21.5784 67.1873L18.2776 68.1295C17.7723 68.2641 17.2334 68.1295 16.8966 67.7257C16.5598 67.3219 16.4588 66.7836 16.6272 66.3125L17.9071 63.1159C18.3786 61.9046 18.2439 60.5586 17.5366 59.5155L15.6167 56.6554C15.3472 56.2517 15.3136 55.7806 15.5157 55.3432C15.6167 54.973 15.8862 54.7375 16.1556 54.6029Z" fill="white"/>
                                </g>
                                <g id="star3" filter="url(#filter2_gd)">
                                    <path d="M9.7896 89.5437C9.85696 89.51 9.92433 89.51 9.99169 89.51L14.1346 89.7792C14.4377 89.8128 14.7408 89.7455 15.0103 89.611C15.2798 89.4764 15.5155 89.3081 15.7176 89.0726L18.2774 85.8087C18.3111 85.7414 18.3785 85.7078 18.4459 85.6741C18.5806 85.6068 18.7153 85.6405 18.85 85.6741C18.9511 85.7078 19.0521 85.8087 19.0858 85.9769L20.1299 90.0147C20.2647 90.6204 20.7025 91.0915 21.2751 91.2934L25.1822 92.7402C25.418 92.8412 25.4854 93.0431 25.4854 93.1104C25.4854 93.2113 25.4854 93.4132 25.2833 93.5478L21.7804 95.7686C21.2751 96.1051 20.9383 96.6434 20.9046 97.2491L20.7362 101.388C20.7362 101.657 20.5341 101.758 20.4668 101.792C20.3994 101.825 20.1973 101.893 19.9952 101.724L16.7954 99.0661C16.3239 98.6623 15.6839 98.5277 15.1113 98.696L11.1032 99.84C10.8674 99.9073 10.699 99.7727 10.6316 99.7054C10.5643 99.6381 10.4632 99.4699 10.5306 99.2344L12.08 95.3648C12.3157 94.7928 12.2484 94.1535 11.9116 93.6487L9.58751 90.183C9.48646 90.0484 9.48646 89.9138 9.52014 89.8128C9.58751 89.7119 9.65487 89.611 9.7896 89.5437Z" fill="url(#paint4_glinear)"/>
                                    <path d="M19.9949 91.1587C20.0285 91.1251 20.0959 91.1587 20.1296 91.1924C20.399 91.4952 20.7359 91.6971 21.1064 91.8653L22.5884 92.4037C22.6557 92.4373 22.6557 92.471 22.6557 92.5383C22.622 92.6056 22.5884 92.6056 22.521 92.6056L21.039 92.0672C20.6348 91.9326 20.2643 91.6634 19.9949 91.3606C19.9275 91.2933 19.9275 91.226 19.9949 91.1587Z" fill="white"/>
                                    <path d="M16.593 88.9719C16.6267 88.9719 16.6604 88.9719 16.6941 88.9719C16.7278 89.0056 16.7614 89.0729 16.7278 89.1065L16.3909 89.5439C16.1215 89.8804 15.7847 90.1496 15.3805 90.3178C14.9763 90.4861 14.5721 90.5534 14.1342 90.5534C14.0669 90.5534 14.0332 90.4861 14.0332 90.4524C14.0332 90.3851 14.1006 90.3515 14.1342 90.3515C14.5384 90.3851 14.9426 90.3178 15.2794 90.1496C15.6499 89.9814 15.9531 89.7458 16.1888 89.443L16.5257 89.0056C16.5594 89.0056 16.5594 88.9719 16.593 88.9719Z" fill="white"/>
                                    <path d="M11.5752 90.3178C11.6426 90.2841 11.7436 90.2505 11.811 90.2505C11.8783 90.2505 11.912 90.2841 11.912 90.3514C11.912 90.4187 11.8783 90.4524 11.811 90.4524C11.5752 90.486 11.4068 90.6206 11.3057 90.8225C11.2047 91.0244 11.2384 91.2599 11.3731 91.4618L12.5856 93.2452C13.0572 93.9854 13.1582 94.8939 12.8551 95.7015L12.0467 97.6867C11.9457 97.9223 12.0131 98.1578 12.1815 98.3597C12.3499 98.5616 12.5856 98.6289 12.8214 98.5616L14.9097 97.9896C15.7517 97.754 16.6612 97.9559 17.3348 98.4943L18.9852 99.8738C19.1873 100.042 19.4231 100.076 19.6588 99.9748C19.8946 99.8738 20.0293 99.672 20.0293 99.4028L20.0967 97.2493C20.1304 96.3744 20.6019 95.5669 21.3429 95.0958L23.1618 93.9518C23.2628 93.8845 23.3639 93.7835 23.3975 93.6826C23.4312 93.6153 23.4986 93.6153 23.5323 93.6153C23.5996 93.6489 23.5996 93.7162 23.5996 93.7499C23.5323 93.8845 23.4312 94.0191 23.2628 94.12L21.444 95.2641C20.7704 95.7015 20.3325 96.4417 20.2988 97.2493L20.2314 99.4028C20.2314 99.7393 20.0293 100.008 19.7262 100.143C19.4231 100.278 19.0863 100.244 18.8168 100.008L17.1664 98.6289C16.5601 98.1241 15.7181 97.9223 14.9434 98.1578L12.8551 98.7298C12.552 98.8308 12.2151 98.7298 11.9794 98.4943C11.7436 98.2587 11.7099 97.9223 11.811 97.6194L12.6193 95.6342C12.9225 94.8939 12.8214 94.0527 12.3836 93.3798L11.171 91.5964C11.0026 91.3609 10.9689 91.0244 11.1036 90.7552C11.2384 90.5197 11.3731 90.3851 11.5752 90.3178Z" fill="white"/>
                                </g>
                                <g id="star4" filter="url(#filter3_gd)">
                                    <path d="M107.314 17.84C107.213 17.8737 107.112 17.941 107.044 18.0419L102.733 23.0891C102.43 23.4593 102.026 23.7285 101.588 23.9304C101.15 24.0986 100.645 24.1659 100.173 24.1322L93.5714 23.4256C93.4367 23.4256 93.3356 23.4256 93.2346 23.4593C93.0325 23.5266 92.8641 23.6948 92.7967 23.9304C92.763 24.0649 92.7294 24.3005 92.8641 24.536L96.2996 30.1889C96.8049 31.0301 96.8722 32.0396 96.4681 32.9481L93.7735 39.0048C93.6051 39.3749 93.8072 39.6777 93.8745 39.7787C93.9756 39.8796 94.2114 40.1152 94.6155 40.0142L101.082 38.4664C102.026 38.2309 103.036 38.5 103.743 39.1394L108.695 43.5809C108.998 43.8501 109.335 43.7828 109.469 43.7155C109.604 43.6482 109.907 43.5136 109.941 43.1099L110.48 36.4812C110.547 35.5054 111.12 34.6305 111.962 34.1594L117.721 30.8282C118.092 30.6264 118.092 30.2899 118.092 30.1553C118.092 30.0207 118.024 29.6842 117.654 29.516L111.524 26.9587C110.614 26.5886 109.974 25.781 109.772 24.8389L108.391 18.3447C108.324 18.0756 108.156 17.941 108.021 17.84C107.718 17.7727 107.482 17.7727 107.314 17.84Z" fill="url(#paint5_glinear)"/>
                                    <path d="M97.7817 31.333C97.7144 31.3667 97.6807 31.434 97.6807 31.5012C97.7144 32.1406 97.6133 32.7799 97.3439 33.3519L96.2997 35.6736C96.2661 35.7746 96.2997 35.8419 96.4008 35.9092C96.5018 35.9428 96.5692 35.9092 96.6366 35.8082L97.6807 33.4865C97.9502 32.8472 98.0849 32.1742 98.0512 31.5012C98.0512 31.4003 97.9502 31.333 97.8828 31.333C97.8154 31.333 97.7817 31.333 97.7817 31.333Z" fill="white"/>
                                    <path d="M99.062 24.974C98.9946 25.0076 98.9609 25.0413 98.9609 25.1086C98.9609 25.2095 99.0283 25.2768 99.0957 25.2768L99.9714 25.3778C100.645 25.445 101.352 25.3441 101.992 25.1086C102.632 24.8394 103.205 24.4356 103.643 23.9309C103.71 23.8636 103.71 23.7626 103.609 23.6953C103.542 23.628 103.441 23.628 103.373 23.729C102.969 24.2001 102.43 24.5702 101.858 24.8057C101.285 25.0413 100.645 25.1422 100.005 25.0749L99.1293 24.974C99.1293 24.974 99.0957 24.974 99.062 24.974Z" fill="white"/>
                                    <path d="M106.202 20.7005C106.067 20.7341 105.966 20.8014 105.865 20.9024C105.798 20.9696 105.798 21.0706 105.831 21.1379C105.899 21.2052 106 21.2052 106.067 21.1715C106.337 20.936 106.707 20.9024 107.044 21.0033C107.381 21.1379 107.616 21.4071 107.717 21.7436L108.425 25.1084C108.728 26.488 109.671 27.5984 110.951 28.1367L114.151 29.449C114.521 29.6173 114.757 29.9201 114.791 30.3239C114.824 30.7276 114.622 31.0978 114.285 31.266L111.288 32.9821C110.075 33.6887 109.301 34.9337 109.166 36.3133L108.896 39.8127C108.863 40.2164 108.627 40.5529 108.256 40.6875C107.886 40.8221 107.482 40.7548 107.179 40.4856L104.619 38.1976C103.575 37.2554 102.16 36.8853 100.779 37.2218L97.4108 38.0293C97.2087 38.063 97.0067 38.063 96.8046 37.9957C96.7035 37.962 96.6362 37.9957 96.6025 38.0966C96.5688 38.1976 96.6025 38.2649 96.7035 38.2985C96.9393 38.3994 97.2087 38.3994 97.4782 38.3321L100.846 37.5246C102.093 37.2218 103.406 37.5582 104.383 38.4331L106.943 40.7212C107.347 41.0577 107.886 41.1586 108.357 40.9904C108.829 40.7885 109.166 40.351 109.199 39.8463L109.469 36.4142C109.57 35.1356 110.311 33.9915 111.422 33.3522L114.42 31.6361C114.858 31.367 115.127 30.8959 115.094 30.3575C115.06 29.8528 114.723 29.3817 114.252 29.1798L111.052 27.8675C109.873 27.3628 108.997 26.3197 108.728 25.0747L108.021 21.7099C107.92 21.2388 107.583 20.8687 107.145 20.7005C106.842 20.5995 106.505 20.5995 106.202 20.7005Z" fill="white"/>
                                </g>
                                <g id="star5" filter="url(#filter4_gd)">
                                    <path d="M134.293 39.1397C134.225 39.1733 134.158 39.207 134.124 39.2743L131.43 42.4372C131.227 42.6727 130.992 42.841 130.722 42.9419C130.453 43.0429 130.15 43.1102 129.847 43.0765L125.704 42.6391C125.636 42.6391 125.535 42.6391 125.502 42.6727C125.367 42.7064 125.266 42.841 125.232 42.9756C125.198 43.0765 125.198 43.2111 125.266 43.3457L127.455 46.8788C127.758 47.3835 127.826 48.0565 127.556 48.5948L125.872 52.3971C125.771 52.6326 125.872 52.8009 125.939 52.8682C126.007 52.9355 126.141 53.0701 126.411 53.0364L130.453 52.0606C131.059 51.926 131.665 52.0606 132.137 52.498L135.236 55.2908C135.438 55.4591 135.64 55.3918 135.707 55.3918C135.775 55.3581 135.977 55.2572 136.01 54.988L136.347 50.8493C136.381 50.2436 136.751 49.7052 137.257 49.4024L140.86 47.3162C141.096 47.1816 141.096 46.9797 141.096 46.8788C141.096 46.7778 141.063 46.5759 140.827 46.475L136.886 44.8599C136.313 44.6243 135.909 44.1196 135.775 43.5476L134.899 39.4762C134.865 39.3079 134.764 39.207 134.663 39.1733C134.562 39.0724 134.394 39.0724 134.293 39.1397Z" fill="url(#paint6_glinear)"/>
                                    <path d="M128.331 47.5513C128.297 47.5513 128.263 47.6186 128.263 47.6522C128.297 48.056 128.23 48.4598 128.061 48.7963L127.421 50.2431C127.388 50.3104 127.421 50.3441 127.489 50.3777C127.556 50.4114 127.59 50.3777 127.624 50.3104L128.263 48.8636C128.432 48.4598 128.499 48.0223 128.499 47.6186C128.466 47.5849 128.432 47.5513 128.331 47.5513Z" fill="white"/>
                                    <path d="M129.139 43.5809C129.105 43.5809 129.071 43.6145 129.071 43.6818C129.071 43.7491 129.105 43.7828 129.172 43.7828L129.711 43.8501C130.149 43.8837 130.587 43.8501 130.957 43.6818C131.362 43.5136 131.698 43.278 132.002 42.9416C132.035 42.9079 132.035 42.8406 132.002 42.807C131.968 42.7733 131.901 42.7733 131.867 42.807C131.597 43.1098 131.294 43.3453 130.924 43.4799C130.553 43.6145 130.149 43.6818 129.779 43.6482L129.24 43.5809C129.172 43.5809 129.139 43.5809 129.139 43.5809Z" fill="white"/>
                                    <path d="M133.585 40.9227C133.517 40.9564 133.45 40.99 133.383 41.0573C133.349 41.091 133.315 41.1583 133.383 41.1919C133.416 41.2256 133.484 41.2592 133.517 41.1919C133.686 41.0573 133.922 41.0237 134.124 41.091C134.326 41.1583 134.494 41.3265 134.528 41.562L134.966 43.6819C135.134 44.5231 135.74 45.2297 136.549 45.5662L138.536 46.4074C138.772 46.5083 138.907 46.7102 138.94 46.9458C138.94 47.1813 138.839 47.4168 138.603 47.5514L136.751 48.6282C136.01 49.0656 135.505 49.8395 135.437 50.7144L135.269 52.8679C135.235 53.1034 135.1 53.3389 134.865 53.4062C134.629 53.5072 134.393 53.4399 134.191 53.2716L132.574 51.8248C131.934 51.2527 131.025 51.0172 130.183 51.2191L128.095 51.7238C127.96 51.7575 127.825 51.7575 127.724 51.6902C127.657 51.6565 127.623 51.6902 127.589 51.7575C127.556 51.8248 127.589 51.8584 127.657 51.8921C127.825 51.9593 127.994 51.9593 128.162 51.9257L130.25 51.421C131.025 51.2191 131.867 51.4546 132.473 51.993L134.09 53.4399C134.326 53.6754 134.663 53.7091 134.966 53.6081C135.269 53.4735 135.471 53.2043 135.505 52.8679L135.673 50.7144C135.74 49.9068 136.178 49.2002 136.886 48.7964L138.738 47.7197C139.008 47.5514 139.176 47.2486 139.176 46.9121C139.142 46.5756 138.94 46.3064 138.637 46.1719L136.65 45.3306C135.909 45.0278 135.37 44.3548 135.202 43.5809L134.764 41.4611C134.696 41.1583 134.494 40.9227 134.225 40.8218C133.989 40.8554 133.787 40.8554 133.585 40.9227Z" fill="white"/>
                                </g>
                            </g>
                            <defs>
                                <filter id="filter0_gd" x="35.6064" y="6" width="59.4139" height="59.9053" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset dx="4" dy="5"/>
                                    <feGaussianBlur stdDeviation="6.5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <filter id="filter1_gd" x="3.79395" y="39.1362" width="51.5921" height="51.914" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset dx="4" dy="5"/>
                                    <feGaussianBlur stdDeviation="6.5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <filter id="filter2_gd" x="0.5" y="77.6353" width="41.9854" height="42.1984" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset dx="4" dy="5"/>
                                    <feGaussianBlur stdDeviation="6.5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <filter id="filter3_gd" x="83.7666" y="9.78955" width="51.3252" height="51.9862" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset dx="4" dy="5"/>
                                    <feGaussianBlur stdDeviation="6.5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <filter id="filter4_gd" x="116.21" y="31.0928" width="41.8863" height="42.3123" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset dx="4" dy="5"/>
                                    <feGaussianBlur stdDeviation="6.5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <linearGradient id="paint0_glinear" x1="50" y1="49" x2="115.5" y2="165" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FAFF11"/>
                                    <stop stop-color="#FAFF11"/>
                                    <stop offset="1" stop-color="#02E319"/>
                                </linearGradient>
                                <linearGradient id="paint1_glinear" x1="92" y1="73" x2="92" y2="142" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FAFF11"/>
                                    <stop stop-color="#FAFF11"/>
                                    <stop offset="1" stop-color="#02E319"/>
                                </linearGradient>
                                <linearGradient id="paint2_glinear" x1="61.3134" y1="14" x2="61.3134" y2="47.9053" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FAFF11"/>
                                    <stop offset="1" stop-color="#FAFF11"/>
                                </linearGradient>
                                <linearGradient id="paint3_glinear" x1="25.59" y1="47.1362" x2="25.59" y2="73.0502" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FAFF11"/>
                                    <stop offset="1" stop-color="#FAFF11"/>
                                </linearGradient>
                                <linearGradient id="paint4_glinear" x1="17.4927" y1="85.6353" x2="17.4927" y2="101.834" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FAFF11"/>
                                    <stop offset="1" stop-color="#FAFF11"/>
                                </linearGradient>
                                <linearGradient id="paint5_glinear" x1="105.429" y1="17.7896" x2="105.429" y2="43.7757" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FAFF11"/>
                                    <stop offset="1" stop-color="#FAFF11"/>
                                </linearGradient>
                                <linearGradient id="paint6_glinear" x1="133.153" y1="39.0928" x2="133.153" y2="55.4051" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FAFF11"/>
                                    <stop offset="1" stop-color="#FAFF11"/>
                                </linearGradient>
                            </defs>
                        </svg>
        );
    }
    if (color === 'blue') {
        return (
        <svg id="eg_1" class="blue_eg_1" width="188" height="187" viewBox="0 0 188 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="eg_1_line" d="M91.5 171C126.846 171 155.5 142.346 155.5 107C155.5 71.6538 126.846 43 91.5 43C56.1538 43 27.5 71.6538 27.5 107C27.5 142.346 56.1538 171 91.5 171Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-dasharray="5.2 5.2"/>
                            <path id="eg_1_line2" d="M91.5002 165C123.533 165 149.5 139.033 149.5 107C149.5 74.9675 123.533 49 91.5002 49C59.4676 49 33.5 74.9675 33.5 107C33.5 139.033 59.4676 165 91.5002 165Z" fill="url(#paint0_blinear)"/>
                            <path opacity="0.3" d="M62.5007 62.0001C41.3007 75.2001 36.6674 97.1667 37.0007 106.5C53.4007 54.8999 98.8341 55.3333 119.501 62.0001C109.334 56.5001 83.7007 48.8001 62.5007 62.0001Z" fill="#FFD600"/>
                            <path id="eg_1_line3" d="M92 154C117.681 154 138.5 133.181 138.5 107.5C138.5 81.8188 117.681 61 92 61C66.3188 61 45.5 81.8188 45.5 107.5C45.5 133.181 66.3188 154 92 154Z" fill="#FFD600"/>
                            <path id="eg_1_line4" d="M92 142C111.054 142 126.5 126.554 126.5 107.5C126.5 88.4462 111.054 73 92 73C72.9462 73 57.5 88.4462 57.5 107.5C57.5 126.554 72.9462 142 92 142Z" fill="url(#paint1_blinear)"/>
                            <path id="eg_e"d="M110.665 115.195C108.924 119.061 105.956 122.24 102.22 124.24C98.4848 126.24 94.1912 126.948 90.0052 126.255C85.8192 125.562 81.9747 123.507 79.0681 120.407C76.1615 117.308 74.3552 113.338 73.9293 109.114C73.5034 104.889 74.4817 100.646 76.7125 97.0417C78.9433 93.4376 82.3019 90.6744 86.2675 89.1804C90.233 87.6865 94.5838 87.5454 98.6452 88.779C102.707 90.0126 106.251 92.552 108.73 96.0033L93.0974 107.23L110.665 115.195Z" fill="#101527"/>
                            <g id='stars'>
                                <g id="star1" filter="url(#filter0_bd)">
                                <path d="M45.1698 22.1812C45.3046 22.1139 45.473 22.0802 45.6077 22.1139L54.2976 22.6859C54.9376 22.7196 55.5439 22.6186 56.1165 22.3494C56.689 22.0802 57.1943 21.7101 57.5985 21.2054L62.9876 14.3748C63.0886 14.2402 63.2233 14.1393 63.3581 14.072C63.6275 13.971 63.9307 13.971 64.1664 14.1056C64.3348 14.2065 64.5706 14.3748 64.6717 14.7113L66.8273 23.1233C67.1304 24.3683 68.0399 25.3441 69.2524 25.8152L77.4034 28.8099C77.9086 29.0118 78.0097 29.4156 78.0097 29.6174C78.0434 29.7857 78.0097 30.2231 77.5718 30.5259L70.2292 35.1694C69.1514 35.8424 68.4777 37.0201 68.444 38.2987L68.1072 46.9799C68.0735 47.5183 67.703 47.7538 67.5346 47.8211C67.3662 47.8884 66.962 48.023 66.5242 47.6866L59.8215 42.1346C58.8447 41.327 57.5311 41.0578 56.2849 41.3943L47.8981 43.7497C47.3928 43.8843 47.056 43.6151 46.9213 43.4805C46.7866 43.3459 46.5508 42.9758 46.7529 42.4711L49.9527 34.3955C50.4242 33.2178 50.2895 31.8719 49.5822 30.8288L44.7657 23.5944C44.5636 23.2916 44.5972 22.9887 44.6309 22.8205C44.732 22.5513 44.9341 22.3158 45.1698 22.1812Z" fill="#FFD600"/>
                                </g>
                                <g id="star2" filter="url(#filter1_bd)">
                                <path d="M13.3257 53.3912C13.4267 53.3576 13.5615 53.3239 13.6625 53.3239L20.2978 53.7613C20.7694 53.795 21.2746 53.694 21.6788 53.5258C22.1167 53.3239 22.4872 53.0211 22.7903 52.651L26.8995 47.4355C26.9669 47.3345 27.0679 47.2672 27.169 47.1999C27.371 47.099 27.6068 47.1326 27.8089 47.1999C27.9436 47.2672 28.112 47.4018 28.1794 47.671L29.8298 54.0978C30.0656 55.04 30.7729 55.8139 31.6823 56.1504L37.9135 58.4385C38.284 58.573 38.385 58.9095 38.385 59.0441C38.385 59.1787 38.385 59.5152 38.0482 59.7507L32.4233 63.3174C31.615 63.8222 31.0761 64.7307 31.0424 65.7065L30.7729 72.3352C30.7729 72.739 30.4698 72.9072 30.3351 72.9745C30.2003 73.0418 29.8972 73.1427 29.5604 72.8735L24.4407 68.6339C23.6997 68.0282 22.6893 67.7927 21.7462 68.0618L15.3466 69.8789C14.9424 69.9798 14.7067 69.7779 14.6056 69.677C14.5046 69.576 14.3025 69.3068 14.4709 68.903L16.9297 62.7454C17.3002 61.8369 17.1654 60.8275 16.6265 60.0199L12.9215 54.5016C12.7531 54.2661 12.7868 54.0305 12.8205 53.8959C12.9889 53.6604 13.1573 53.4585 13.3257 53.3912Z" fill="#FFD600"/>
                                <path d="M29.6623 55.949C29.7297 55.9153 29.7971 55.949 29.8644 55.9826C30.3023 56.4537 30.8075 56.8239 31.4138 57.0257L33.7715 57.9006C33.8726 57.9342 33.9063 58.0352 33.8726 58.1025C33.8389 58.2034 33.7378 58.2371 33.6705 58.2034L31.3127 57.3286C30.6728 57.093 30.1002 56.6893 29.6286 56.1845C29.5613 56.1172 29.5613 56.0163 29.6286 55.949C29.6286 55.9826 29.6286 55.9826 29.6623 55.949Z" fill="#FFD600"/>
                                <path d="M24.2049 52.4828C24.2723 52.4491 24.3396 52.4491 24.3733 52.5164C24.4407 52.5837 24.4744 52.6847 24.407 52.752L23.8681 53.4586C23.4302 53.997 22.8913 54.4344 22.2513 54.7036C21.6114 54.9728 20.9378 55.1074 20.2641 55.0737C20.1631 55.0737 20.0957 54.9728 20.0957 54.9055C20.0957 54.8045 20.1967 54.7372 20.2641 54.7372C20.9041 54.7709 21.544 54.6699 22.1166 54.4007C22.6892 54.1316 23.1944 53.7614 23.5986 53.2567L24.1375 52.5501C24.1375 52.4828 24.1712 52.4828 24.2049 52.4828Z" fill="#FFD600"/>
                                <path d="M16.1546 54.6029C16.2894 54.5356 16.3904 54.502 16.5251 54.502C16.6262 54.502 16.6935 54.5692 16.7272 54.6365C16.7272 54.7375 16.6599 54.8048 16.5925 54.8384C16.2557 54.8721 15.9525 55.1076 15.7841 55.4441C15.6494 55.7806 15.6494 56.1507 15.8515 56.4535L17.7714 59.3136C18.5461 60.4577 18.7145 61.9382 18.1755 63.2505L16.8956 66.4471C16.7609 66.8172 16.8283 67.221 17.0977 67.5238C17.3672 67.8267 17.7714 67.9276 18.1419 67.8267L21.4427 66.8845C22.79 66.5144 24.2383 66.8172 25.3161 67.6921L27.977 69.8792C28.2801 70.1484 28.6843 70.182 29.0548 70.0138C29.4253 69.8456 29.6611 69.5091 29.6611 69.1053L29.7958 65.6732C29.8632 64.2936 30.6042 63.015 31.783 62.2411L34.6797 60.3904C34.8481 60.2895 34.9828 60.1212 35.0839 59.953C35.1175 59.852 35.2186 59.8184 35.3196 59.8857C35.4207 59.9193 35.4544 60.0203 35.387 60.1212C35.286 60.3567 35.0839 60.5586 34.8818 60.6932L31.9851 62.5439C30.9073 63.2168 30.2337 64.3945 30.1663 65.7068L30.0316 69.1389C29.9979 69.6773 29.6948 70.1147 29.2232 70.3166C28.7517 70.5185 28.2128 70.4512 27.8086 70.1147L25.1477 67.9276C24.1709 67.12 22.8237 66.8172 21.5774 67.1873L18.2766 68.1295C17.7714 68.2641 17.2325 68.1295 16.8956 67.7257C16.5588 67.3219 16.4578 66.7836 16.6262 66.3125L17.9061 63.1159C18.3776 61.9046 18.2429 60.5586 17.5356 59.5155L15.6157 56.6554C15.3463 56.2517 15.3126 55.7806 15.5147 55.3432C15.6157 54.973 15.8852 54.7375 16.1546 54.6029Z" fill="#FFD600"/>
                                </g>
                                <g id="star3" filter="url(#filter2_bd)">
                                <path d="M9.7896 89.5437C9.85696 89.51 9.92433 89.51 9.99169 89.51L14.1346 89.7792C14.4377 89.8128 14.7408 89.7455 15.0103 89.611C15.2798 89.4764 15.5155 89.3081 15.7176 89.0726L18.2774 85.8087C18.3111 85.7414 18.3785 85.7078 18.4459 85.6741C18.5806 85.6068 18.7153 85.6405 18.85 85.6741C18.9511 85.7078 19.0521 85.8087 19.0858 85.9769L20.1299 90.0147C20.2647 90.6204 20.7025 91.0915 21.2751 91.2934L25.1822 92.7402C25.418 92.8412 25.4854 93.0431 25.4854 93.1104C25.4854 93.2113 25.4854 93.4132 25.2833 93.5478L21.7804 95.7686C21.2751 96.1051 20.9383 96.6434 20.9046 97.2491L20.7362 101.388C20.7362 101.657 20.5341 101.758 20.4668 101.792C20.3994 101.825 20.1973 101.893 19.9952 101.724L16.7954 99.0661C16.3239 98.6623 15.6839 98.5277 15.1113 98.696L11.1032 99.84C10.8674 99.9073 10.699 99.7727 10.6316 99.7054C10.5643 99.6381 10.4632 99.4699 10.5306 99.2344L12.08 95.3648C12.3157 94.7928 12.2484 94.1535 11.9116 93.6487L9.58751 90.183C9.48646 90.0484 9.48646 89.9138 9.52014 89.8128C9.58751 89.7119 9.65487 89.611 9.7896 89.5437Z" fill="#FFD600"/>
                                <path d="M19.9958 91.1587C20.0295 91.1251 20.0969 91.1587 20.1306 91.1924C20.4 91.4952 20.7368 91.6971 21.1073 91.8653L22.5893 92.4037C22.6567 92.4373 22.6567 92.471 22.6567 92.5383C22.623 92.6056 22.5893 92.6056 22.522 92.6056L21.04 92.0672C20.6358 91.9326 20.2653 91.6634 19.9958 91.3606C19.9285 91.2933 19.9285 91.226 19.9958 91.1587Z" fill="#FFD600"/>
                                <path d="M16.593 88.9719C16.6267 88.9719 16.6604 88.9719 16.6941 88.9719C16.7278 89.0056 16.7614 89.0729 16.7278 89.1065L16.3909 89.5439C16.1215 89.8804 15.7847 90.1496 15.3805 90.3178C14.9763 90.4861 14.5721 90.5534 14.1342 90.5534C14.0669 90.5534 14.0332 90.4861 14.0332 90.4524C14.0332 90.3851 14.1006 90.3515 14.1342 90.3515C14.5384 90.3851 14.9426 90.3178 15.2794 90.1496C15.6499 89.9814 15.9531 89.7458 16.1888 89.443L16.5257 89.0056C16.5594 89.0056 16.5594 88.9719 16.593 88.9719Z" fill="#FFD600"/>
                                <path d="M11.5742 90.3178C11.6416 90.2841 11.7426 90.2505 11.81 90.2505C11.8773 90.2505 11.911 90.2841 11.911 90.3514C11.911 90.4187 11.8773 90.4524 11.81 90.4524C11.5742 90.486 11.4058 90.6206 11.3048 90.8225C11.2037 91.0244 11.2374 91.2599 11.3721 91.4618L12.5847 93.2452C13.0562 93.9854 13.1573 94.8939 12.8541 95.7015L12.0458 97.6867C11.9447 97.9223 12.0121 98.1578 12.1805 98.3597C12.3489 98.5616 12.5847 98.6289 12.8204 98.5616L14.9087 97.9896C15.7508 97.754 16.6602 97.9559 17.3338 98.4943L18.9842 99.8738C19.1863 100.042 19.4221 100.076 19.6579 99.9748C19.8936 99.8738 20.0284 99.672 20.0284 99.4028L20.0957 97.2493C20.1294 96.3744 20.601 95.5669 21.342 95.0958L23.1608 93.9518C23.2618 93.8845 23.3629 93.7835 23.3966 93.6826C23.4302 93.6153 23.4976 93.6153 23.5313 93.6153C23.5987 93.6489 23.5987 93.7162 23.5987 93.7499C23.5313 93.8845 23.4302 94.0191 23.2618 94.12L21.443 95.2641C20.7694 95.7015 20.3315 96.4417 20.2978 97.2493L20.2305 99.4028C20.2305 99.7393 20.0284 100.008 19.7252 100.143C19.4221 100.278 19.0853 100.244 18.8158 100.008L17.1654 98.6289C16.5591 98.1241 15.7171 97.9223 14.9424 98.1578L12.8541 98.7298C12.551 98.8308 12.2142 98.7298 11.9784 98.4943C11.7426 98.2587 11.7089 97.9223 11.81 97.6194L12.6184 95.6342C12.9215 94.8939 12.8204 94.0527 12.3826 93.3798L11.17 91.5964C11.0016 91.3609 10.9679 91.0244 11.1027 90.7552C11.2374 90.5197 11.3721 90.3851 11.5742 90.3178Z" fill="#FFD600"/>
                                </g>
                                <g id="star4" filter="url(#filter3_bd)">
                                <path d="M107.315 17.84C107.214 17.8737 107.112 17.941 107.045 18.0419L102.734 23.0891C102.431 23.4593 102.027 23.7285 101.589 23.9304C101.151 24.0986 100.646 24.1659 100.174 24.1322L93.5724 23.4256C93.4377 23.4256 93.3366 23.4256 93.2356 23.4593C93.0335 23.5266 92.8651 23.6948 92.7977 23.9304C92.764 24.0649 92.7303 24.3005 92.8651 24.536L96.3006 30.1889C96.8058 31.0301 96.8732 32.0396 96.469 32.9481L93.7745 39.0048C93.6061 39.3749 93.8082 39.6777 93.8755 39.7787C93.9766 39.8796 94.2123 40.1152 94.6165 40.0142L101.083 38.4664C102.027 38.2309 103.037 38.5 103.744 39.1394L108.696 43.5809C108.999 43.8501 109.335 43.7828 109.47 43.7155C109.605 43.6482 109.908 43.5136 109.942 43.1099L110.481 36.4812C110.548 35.5054 111.121 34.6305 111.963 34.1594L117.722 30.8282C118.093 30.6264 118.093 30.2899 118.093 30.1553C118.093 30.0207 118.025 29.6842 117.655 29.516L111.525 26.9587C110.615 26.5886 109.975 25.781 109.773 24.8389L108.392 18.3447C108.325 18.0756 108.157 17.941 108.022 17.84C107.719 17.7727 107.483 17.7727 107.315 17.84Z" fill="#FFD600"/>
                                <path d="M97.7817 31.333C97.7144 31.3667 97.6807 31.434 97.6807 31.5012C97.7144 32.1406 97.6133 32.7799 97.3439 33.3519L96.2997 35.6736C96.2661 35.7746 96.2997 35.8419 96.4008 35.9092C96.5018 35.9428 96.5692 35.9092 96.6366 35.8082L97.6807 33.4865C97.9502 32.8472 98.0849 32.1742 98.0512 31.5012C98.0512 31.4003 97.9502 31.333 97.8828 31.333C97.8154 31.333 97.7817 31.333 97.7817 31.333Z" fill="#FFD600"/>
                                <path d="M99.062 24.974C98.9946 25.0076 98.9609 25.0413 98.9609 25.1086C98.9609 25.2095 99.0283 25.2768 99.0957 25.2768L99.9714 25.3778C100.645 25.445 101.352 25.3441 101.992 25.1086C102.632 24.8394 103.205 24.4356 103.643 23.9309C103.71 23.8636 103.71 23.7626 103.609 23.6953C103.542 23.628 103.441 23.628 103.373 23.729C102.969 24.2001 102.43 24.5702 101.858 24.8057C101.285 25.0413 100.645 25.1422 100.005 25.0749L99.1293 24.974C99.1293 24.974 99.0957 24.974 99.062 24.974Z" fill="#FFD600"/>
                                <path d="M106.204 20.7005C106.069 20.7341 105.968 20.8014 105.867 20.9024C105.8 20.9696 105.8 21.0706 105.833 21.1379C105.901 21.2052 106.002 21.2052 106.069 21.1715C106.338 20.936 106.709 20.9024 107.046 21.0033C107.383 21.1379 107.618 21.4071 107.719 21.7436L108.427 25.1084C108.73 26.488 109.673 27.5984 110.953 28.1367L114.153 29.449C114.523 29.6173 114.759 29.9201 114.793 30.3239C114.826 30.7276 114.624 31.0978 114.287 31.266L111.29 32.9821C110.077 33.6887 109.302 34.9337 109.168 36.3133L108.898 39.8127C108.865 40.2165 108.629 40.5529 108.258 40.6875C107.888 40.8221 107.484 40.7548 107.181 40.4856L104.621 38.1976C103.577 37.2554 102.162 36.8853 100.781 37.2218L97.4128 38.0293C97.2107 38.063 97.0086 38.063 96.8065 37.9957C96.7055 37.962 96.6381 37.9957 96.6044 38.0966C96.5707 38.1976 96.6044 38.2649 96.7055 38.2985C96.9413 38.3994 97.2107 38.3994 97.4802 38.3321L100.848 37.5246C102.095 37.2218 103.408 37.5582 104.385 38.4331L106.945 40.7212C107.349 41.0577 107.888 41.1586 108.359 40.9904C108.831 40.7885 109.168 40.351 109.201 39.8463L109.471 36.4142C109.572 35.1356 110.313 33.9915 111.424 33.3522L114.422 31.6361C114.86 31.367 115.129 30.8959 115.096 30.3575C115.062 29.8528 114.725 29.3817 114.254 29.1798L111.054 27.8675C109.875 27.3628 108.999 26.3197 108.73 25.0747L108.023 21.7099C107.922 21.2388 107.585 20.8687 107.147 20.7005C106.844 20.5995 106.507 20.5995 106.204 20.7005Z" fill="#FFD600"/>
                                </g>
                                <g id="star5" filter="url(#filter4_bd)">
                                <path d="M134.293 39.1397C134.226 39.1733 134.159 39.207 134.125 39.2743L131.431 42.4372C131.228 42.6727 130.993 42.841 130.723 42.9419C130.454 43.0429 130.151 43.1102 129.847 43.0765L125.705 42.6391C125.637 42.6391 125.536 42.6391 125.503 42.6727C125.368 42.7064 125.267 42.841 125.233 42.9756C125.199 43.0765 125.199 43.2111 125.267 43.3457L127.456 46.8788C127.759 47.3835 127.827 48.0565 127.557 48.5948L125.873 52.3971C125.772 52.6326 125.873 52.8009 125.94 52.8682C126.008 52.9355 126.142 53.0701 126.412 53.0364L130.454 52.0606C131.06 51.926 131.666 52.0606 132.138 52.498L135.237 55.2908C135.439 55.4591 135.641 55.3918 135.708 55.3918C135.776 55.3581 135.978 55.2572 136.011 54.988L136.348 50.8493C136.382 50.2436 136.752 49.7052 137.258 49.4024L140.861 47.3162C141.097 47.1816 141.097 46.9797 141.097 46.8788C141.097 46.7778 141.064 46.5759 140.828 46.475L136.887 44.8599C136.314 44.6243 135.91 44.1196 135.776 43.5476L134.9 39.4762C134.866 39.3079 134.765 39.207 134.664 39.1733C134.563 39.0724 134.395 39.0724 134.293 39.1397Z" fill="#FFD600"/>
                                <path d="M128.331 47.5513C128.297 47.5513 128.263 47.6186 128.263 47.6522C128.297 48.056 128.23 48.4598 128.061 48.7963L127.421 50.2431C127.388 50.3104 127.421 50.3441 127.489 50.3777C127.556 50.4114 127.59 50.3777 127.624 50.3104L128.263 48.8636C128.432 48.4598 128.499 48.0223 128.499 47.6186C128.466 47.5849 128.432 47.5513 128.331 47.5513Z" fill="#FFD600"/>
                                <path d="M129.14 43.5809C129.106 43.5809 129.072 43.6145 129.072 43.6818C129.072 43.7491 129.106 43.7828 129.173 43.7828L129.712 43.8501C130.15 43.8837 130.588 43.8501 130.958 43.6818C131.363 43.5136 131.699 43.278 132.003 42.9416C132.036 42.9079 132.036 42.8406 132.003 42.807C131.969 42.7733 131.902 42.7733 131.868 42.807C131.598 43.1098 131.295 43.3453 130.925 43.4799C130.554 43.6145 130.15 43.6818 129.78 43.6482L129.241 43.5809C129.173 43.5809 129.14 43.5809 129.14 43.5809Z" fill="#FFD600"/>
                                <path d="M133.587 40.9227C133.519 40.9564 133.452 40.99 133.385 41.0573C133.351 41.091 133.317 41.1583 133.385 41.1919C133.418 41.2256 133.486 41.2592 133.519 41.1919C133.688 41.0573 133.924 41.0237 134.126 41.091C134.328 41.1583 134.496 41.3265 134.53 41.562L134.968 43.6819C135.136 44.5231 135.742 45.2297 136.551 45.5662L138.538 46.4074C138.774 46.5083 138.908 46.7102 138.942 46.9458C138.942 47.1813 138.841 47.4168 138.605 47.5514L136.753 48.6282C136.012 49.0656 135.507 49.8395 135.439 50.7144L135.271 52.8679C135.237 53.1034 135.102 53.3389 134.867 53.4062C134.631 53.5072 134.395 53.4399 134.193 53.2716L132.576 51.8248C131.936 51.2527 131.027 51.0172 130.185 51.2191L128.097 51.7238C127.962 51.7575 127.827 51.7575 127.726 51.6902C127.659 51.6565 127.625 51.6902 127.591 51.7575C127.558 51.8248 127.591 51.8584 127.659 51.8921C127.827 51.9593 127.996 51.9593 128.164 51.9257L130.252 51.421C131.027 51.2191 131.869 51.4546 132.475 51.993L134.092 53.4399C134.328 53.6754 134.665 53.7091 134.968 53.6081C135.271 53.4735 135.473 53.2043 135.507 52.8679L135.675 50.7144C135.742 49.9068 136.18 49.2002 136.888 48.7964L138.74 47.7197C139.01 47.5514 139.178 47.2486 139.178 46.9121C139.144 46.5756 138.942 46.3064 138.639 46.1719L136.652 45.3306C135.911 45.0278 135.372 44.3548 135.203 43.5809L134.766 41.4611C134.698 41.1583 134.496 40.9227 134.227 40.8218C133.991 40.8554 133.789 40.8554 133.587 40.9227Z" fill="#FFD600"/>
                                </g>
                            </g>
                            <defs>
                            <filter id="filter0_bd" x="35.6055" y="6" width="59.4139" height="59.9053" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="4" dy="5"/>
                            <feGaussianBlur stdDeviation="6.5"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                            <filter id="filter1_bd" x="3.79297" y="39.1362" width="51.5921" height="51.914" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="4" dy="5"/>
                            <feGaussianBlur stdDeviation="6.5"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                            <filter id="filter2_bd" x="0.5" y="77.6353" width="41.9854" height="42.1984" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="4" dy="5"/>
                            <feGaussianBlur stdDeviation="6.5"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                            <filter id="filter3_bd" x="83.7676" y="9.78955" width="51.3252" height="51.9862" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="4" dy="5"/>
                            <feGaussianBlur stdDeviation="6.5"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                            <filter id="filter4_bd" x="116.211" y="31.0928" width="41.8863" height="42.3123" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dx="4" dy="5"/>
                            <feGaussianBlur stdDeviation="6.5"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.710521 0 0 0 0 0.752717 0 0 0 0 0.791667 0 0 0 0.6 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                            <linearGradient id="paint0_blinear" x1="59.3365" y1="71.991" x2="126.063" y2="159.314" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#009DFA"/>
                            <stop offset="1" stop-color="#007DC7"/>
                            </linearGradient>
                            <linearGradient id="paint1_blinear" x1="72.8682" y1="86.6757" x2="112.559" y2="138.618" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#009DFA"/>
                            <stop offset="1" stop-color="#007DC7"/>
                            </linearGradient>
                            </defs>
                        </svg>
        );
    }
});

export { View };