import React from 'react';
import { useSelector } from 'react-redux';
import i1 from './../../assets/img/icon01.png';
import i2 from './../../assets/img/icon02.png';
import i3 from './../../assets/img/icon03.png';
import i4 from './../../assets/img/icon04.png';

import i1g from './../../assets/img/icon01green.png';
import i2g from './../../assets/img/icon02green.png';
import i3g from './../../assets/img/icon03green.png';
import i4g from './../../assets/img/icon04green.png';

import i1b from './../../assets/img/icon01blue.png';
import i2b from './../../assets/img/icon02blue.png';
import i3b from './../../assets/img/icon03blue.png';
import i4b from './../../assets/img/icon04blue.png';

import data from '../../assets/data.json';

const View = React.memo(() => {
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    var b5Url1 = (color == 'white') ? i1 : (color == 'green')? i1g : i1b;
    var b5Url2 = (color == 'white') ? i2 : (color == 'green')? i2g : i2b;
    var b5Url3 = (color == 'white') ? i3 : (color == 'green')? i3g : i3b;
    var b5Url4 = (color == 'white') ? i4 : (color == 'green')? i4g : i4b;
	return (
		<section className="block05">
            <div className="wrapper">
                <h2 style={{
                    color: (color == 'white') ? '#101527' : (color == 'green')? '#101527' : '#FFD600'
                }}>
                    {data.lang[lang - 1].block5[0]}
                </h2>
                <div className="list_benefits">
                    <div className="benefit">
                        <img src={b5Url1} alt="icon"/>
                        <h4>
                            {data.lang[lang - 1].block5[1]}
                            <br/>
                            {data.lang[lang - 1].block5[2]}
                        </h4>
                        <p>
                            {data.lang[lang - 1].block5[3]} 
                            <br/>
                            {data.lang[lang - 1].block5[4]} 
                            <br/>
                            {data.lang[lang - 1].block5[5]}
                        </p>
                    </div>
                    <div className="benefit">
                        <img src={b5Url2} alt="icon"/>
                        <h4>
                            {data.lang[lang - 1].block5[6]}
                            <br/>
                            {data.lang[lang - 1].block5[7]}
                        </h4>
                        <p>
                            {data.lang[lang - 1].block5[8]} 
                            <br/>
                            {data.lang[lang - 1].block5[9]} 
                            <br/>
                            {data.lang[lang - 1].block5[10]} 
                            <br/>
                            {data.lang[lang - 1].block5[11]}
                        </p>
                    </div>
                    <div className="benefit">
                        <img src={b5Url3} alt="icon"/>
                        <h4>
                            {data.lang[lang - 1].block5[12]}
                            <br/>
                            {data.lang[lang - 1].block5[13]}
                        </h4>
                        <p>
                            {data.lang[lang - 1].block5[14]}
                            <br/>
                            {data.lang[lang - 1].block5[15]}
                            <br/>
                            {data.lang[lang - 1].block5[16]}
                        </p>
                    </div>
                    <div className="benefit">
                        <img src={b5Url4} alt="icon"/>
                        <h4>
                            {data.lang[lang - 1].block5[17]}
                            <br/>
                            {data.lang[lang - 1].block5[18]}
                        </h4>
                        <p>
                            {data.lang[lang - 1].block5[19]}
                            <br/>
                            {data.lang[lang - 1].block5[20]}
                            <br/>
                            {data.lang[lang - 1].block5[21]}
                            <br/>
                            {data.lang[lang - 1].block5[22]}
                        </p>
                    </div>
                </div>
            </div>
        </section>
        );
});

export { View };