import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Main, Loyalty, Sales, Learning } from './../../pages';

const View = React.memo(() => {
	return (
		<Routes>
			<Route path='/' element={<Main/>}></Route>

			<Route path="*" element={<Main/>}/>

			<Route path='/loyalty' element={<Loyalty/>}></Route>
			<Route path='/sales' element={<Sales/>}></Route>
			<Route path='/learning' element={<Learning/>}></Route>
		</Routes>
	);
});

export { View };