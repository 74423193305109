import React from 'react';
import logoImg from '../../../assets/img/digitum.svg';
import icon1 from '../../../assets/img/inst.png';
import icon2 from '../../../assets/img/fb.png';
import icon3 from '../../../assets/img/youtube.png';

const View = React.memo(() => {

	return (
		<footer>
			<div className="wrapper">
				<div className="socials">
					<a href="https://www.linkedin.com/company/digitum-marketing" target="_blank">
						<img src={icon1} alt="icon"/>
					</a>
					<a href="https://www.facebook.com/digitumproducts" target="_blank">
						<img src={icon2} alt="icon"/>
					</a>
					<a href="https://www.youtube.com/channel/UC61eDCO5KuEuyc5CegrijiQ" target="_blank">
						<img src={icon3} alt="icon"/>
					</a>
				</div>
				<div className="info">
					<p style={{cursor: 'pointer'}} onClick={() => {window.open('https://digitum.agency', '_blank')}}>2022 ©  Digitum</p>

					<p>EASYGROW Powered by <img src={logoImg} style={{cursor: 'pointer'}} onClick={() => {window.open('https://digitum.agency', '_blank')}} alt="logo"/></p>
				</div>
        	</div>
		</footer>);
});

export { View };