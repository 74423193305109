import React from 'react';
import logo from '../../../assets/img/logo.svg';
import { useSelector } from 'react-redux';

const View = React.memo(() => {
	const color = useSelector(state => state.colorSite);
	return (
		<img src={logo} style={{filter: (color == 'white') ? 'invert(0)' : (color == 'green')? 'invert(0)' : 'invert(1)'}} onClick={ () => {window.open('/', '_self')} } className={ 'logo__img' } alt='logo'/>);
});

export { View };