import React from "react";
import Slider from "react-slick";
import { LayoutBase } from '../../layouts';
import { FeedbackForm, ModalHeader } from '../../blocks';
import { settings } from "../../constants/slider";
import point from '../../assets/img/green_point.png';
import slide1 from '../../assets/img/screen2_1.png';
import slide2 from '../../assets/img/screen2_2.png';
import slide3 from '../../assets/img/screen2_3.png';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackModal, setfromWhereAmI } from '../../actions/actions';

import data from '../../assets/data.json';

const View = React.memo( () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const dispatch = useDispatch();
    const color = useSelector(state => state.colorSite);
    const lang = useSelector(state => state.actualLang);
    const fromWhereAmI = useSelector(state => state.fromWhereAmI);
    return(
        <div style={{
            background: (color == 'white') ? '#ffffff' : (color == 'green')? 'linear-gradient(105.73deg, #FDFC47 3.91%, #3EEC55 88.01%)' : 'linear-gradient(142.62deg, #009DFA 20.88%, #007DC7 88.47%)'
        }}>
        <LayoutBase>
            <main className="EG">
                <div className="wrapper">
                    <div className="leftPart">
                        <h2><b>EASYGROW</b> Loyalty</h2>
                        <p >{data.lang[lang - 1].loyalty[0]}</p>
                        <ul>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p >
                                {data.lang[lang - 1].loyalty[1]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p >
                                {data.lang[lang - 1].loyalty[2]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p >
                                {data.lang[lang - 1].loyalty[3]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p >
                                {data.lang[lang - 1].loyalty[4]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p >
                                {data.lang[lang - 1].loyalty[5]}
                                </p>
                            </li>
                            <li>
                                <img width="8px" height="8px" src={point} alt="icon"/>
                                <p >
                                {data.lang[lang - 1].loyalty[6]}
                                </p>
                            </li>
                        </ul>
                        <button id="demoTwo" onClick={() => {dispatch(setFeedbackModal(true)); dispatch(setfromWhereAmI("thank_you_loyalty"));localStorage.setItem('fromWhereAmI', fromWhereAmI);}}>{data.lang[lang - 1].loyalty[7]}</button>
                    </div>
                    <div class="rightPart">
                        <div class="mockUp"></div>
                        <Slider class="iPhone_mock" {...settings}>
                            <img src={slide1} alt="image"/>
                            <img src={slide2} alt="image"/>
                            <img src={slide3} alt="image"/>
                        </Slider>
                    </div>
                </div>
            </main>
        </LayoutBase>
        <FeedbackForm/>
        <ModalHeader/>
        </div>
        );
});

export { View };