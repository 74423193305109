import React, { useEffect } from 'react';
import desktopImg from './../../assets/img/desktop.png';
import iphoneImg from './../../assets/img/iPhone.png';

const View = React.memo(() => {
    useEffect(() => {
        var block = document.getElementsByClassName('block04')[0]; 
        var img1 = document.getElementsByClassName('block04')[0].getElementsByTagName('IMG')[0];
        var img2 = document.getElementsByClassName('block04')[0].getElementsByTagName('IMG')[1];
        var endPosition = block.offsetTop;

        window.addEventListener("scroll", () => {
            var scroll = window.pageYOffset;
            if ((endPosition - scroll) >= 0) {
                img1.style.marginLeft = (scroll - endPosition) + 'px';
                img2.style.marginLeft = -(scroll - endPosition) + 'px';
            } else if ((endPosition - scroll) <= 0) {
                img1.style.marginLeft = '0px';
                img2.style.marginLeft = '0px';
            }
        });
    }, []);
	return (
		<section className="block04">
            <img src={desktopImg} alt="img" style={{transition: 'transform 5s easy-in-out'}}/>
            <img src={iphoneImg} alt="img" style={{transition: 'transform 5s easy-in-out'}}/>
        </section>
        );
});

export { View };