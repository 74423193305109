import React from 'react';
import detectBrowserLanguage from 'detect-browser-language'; // ru-RU, en-US, uk-UA
import { useDispatch, useSelector } from 'react-redux';
import { setBLang, setLang } from '../../../actions/actions';

function getLang() {
	return (detectBrowserLanguage() === 'en-US') ? 'en-US' : (detectBrowserLanguage() === 'uk-UA') ? 'uk-UA' : 'ru-RU';
}

function getActualLang()  {
	return (detectBrowserLanguage() === 'en-US') ? 1 : (detectBrowserLanguage() === 'uk-UA') ? 2 : 3;
}

const View = React.memo(() => {
    const l1 = useSelector(state => state.browserLang);
	const l2 = useSelector(state => state.actualLang);
	const dispatch = useDispatch();
	var checkLang = () => {
        if (l1 === null) {
            dispatch(setBLang(getLang()));
        }
    }

    var checkActualLang = () => {
        if (l2 === null) {
            dispatch(setLang(getActualLang()));
        }
    }

    // var urlManipulation = () => {
    //     if(window.location.href === window.location.protocol + "//" + window.location.host + '/#solutions') {
    //         history.pushState({}, null, window.location.protocol + "//" + window.location.host + window.location.pathname + 'solutions');
    //     }
    //     if(window.location.href === window.location.protocol + "//" + window.location.host + '/#implementation') {
    //         history.pushState({}, null, window.location.protocol + "//" + window.location.host + window.location.pathname + 'implementation');
    //     }
    //     if(window.location.href === window.location.protocol + "//" + window.location.host + '/#our_cases') {
    //         history.pushState({}, null, window.location.protocol + "//" + window.location.host + window.location.pathname + 'our_cases');
    //     }
    //     if(window.location.href === window.location.protocol + "//" + window.location.host + '/#about_us') {
    //         history.pushState({}, null, window.location.protocol + "//" + window.location.host + window.location.pathname + 'about_us');
    //     }
    // }

	return (
        <>
		{checkLang()}
		{checkActualLang()}
			{/* func wich detect browser lang 
                    if it en, hide lang settings block
                */}
                {/* <h1>{l1}</h1> */}
                {/* {"reduuuux browser lang : " + this.props.browserLang} ¿test? */}

                {/* <br/> */}
                {/* func wich detect browser lang 
                    if it ru or ua, show content in similar lang
                */}
                {/* <h1>{l2}</h1> */}
                {/* {"reduuuux actual lang : " + this.props.actualLang} ¿test? */}
        </>
	);
});

export { View };